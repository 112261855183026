import createHttp from '@/uni_modules/base-plugin/utils/request';

export const getMsgByNew = () => {
    return createHttp.get('/msg/getMsgByNew', {}).then((res: any) => {
        return res;
    });
};

export const getList = (data: any) => {
    return createHttp.get('/msg/getList', data).then((res: any) => {
        return res;
    });
};

export const pushMsg = (data: any) => {
    return createHttp.post('/msg/pushMsg', data).then((res: any) => {
        return res;
    });
};

export const pushOddMsg = (data: any) => {
    return createHttp.post('/msg/pushOddMsg', data).then((res: any) => {
        return res;
    });
};
