/* eslint-disable */

/*
 * @Author: zhouyankai
 * @Date: 2023-07-26
 * @LastEditTime: 2023-04-26
 * @LastEditors: zhouyankai
 * @Description:有关权限的pinia
 */
import { defineStore } from 'pinia';

import { getCurrentTime } from '@/uni_modules/base-plugin/util/util';
const WsPATH = import.meta.env.VITE_BASE_WSS;

import type { IChatMessage } from '@/uni_modules/base-plugin/api/chat/types/chat';
import { computed, ref } from 'vue';

import { useAuthStore } from '@/uni_modules/base-plugin/state/modules/auth';
import store from "@/uni_modules/qf-plugin/store";
import * as wsApi from "@/uni_modules/base-plugin/state/modules/chatWeb";
import {personPullOfflineMessage} from "@/uni_modules/base-plugin/api/privateMessage/privateMessage";
import * as enums from "@/uni_modules/base-plugin/state/modules/enums";
import * as msgType from "@/uni_modules/base-plugin/state/modules/messageType";
import {find, findChatPersonnels} from "@/uni_modules/base-plugin/api/chatPersonnel/chatPersonnel";

let APP_VERSION = '1.0.0';
let APP_CHANNEL = 'app';
const APP_PACKAGE = 'com.farsunset.cim';

export interface IChatFriend {
    username: string;
    nickName: string;
    avatarUrl: string;
    message: string; // 最近消息内容
    createTime: number; // 最近消息时间
    type: number; // 最近一条消息类型
    count: number; // 未读消息数目
    group: number; // 默认0，group=1 组数据
}

export interface IUserInfo {
    id?: number; // 主键
    username: string; // 登录名称
}

export interface IUser {
    info: IUserInfo | null;
    token: string | null;
    expiresIn: number | null;
}

export interface IChat {
    token: string;
    setAction: number; // 点击设置人触发
    activeUserId: string; // 当前聊天窗口人
    activeUser: any; // 当前聊天窗口人
    chatList: IChatMessage[]; // 当前聊天窗口消息
    updateId: number; // 更新id
    tempFilePath: Record<string, string>; //临时文件缓存
}

export interface Message {
    id?: number | null; //消息ID
    action: string; //消息类型由开发着自行定义
    title?: string | null; //消息标题
    content?: string | null; //消息内容
    sender?: string | null; //消息发送者账号或者ID
    receiver: string; //消息接收者账号或者ID
    extra?: string | null; //附加扩展内容
    format?: string | null; //消息内容格式(类型)，可表示content的内容信息，如图片、语音、文件、视频，名片，超链接等 content 可以记录每个类型对应的json数据
    timestamp?: number | null; //消息产生的时间戳 13位
}

export interface SentBody {
    key: string; //服务端定义功能接口唯一标识
    timestamp: number; //时间戳13位
    data: Record<string, any>;
}

export interface ReplyBody {
    key: string; //服务端定义的请求标识
    code: string; //服务的返回的状态码
    message: string; //说明字段
    timestamp: number; //响应时间戳13位
    data: Record<string, any>; //返回的数据
}

console.log('chat store init');

let beatInterval: number;

export const useChatStore = defineStore('chat', () => {

    let state = ref<IChat>({
        token: uni.getStorageSync('token'),
        setAction: 0,
        activeUserId: '',
        activeUser: {},
        chatList: [],
        updateId: 0,
        tempFilePath: {}, //临时文件
    });

    const { skills, birth, sex } = useAuthStore().skiller


    let aiStep = ref('skills')

    let genderVal = ref('')

    /**
     * 修改这个值，会触发chatList watch
     */
    let skillsVal = ref([])

    let chatList = computed(() => state.value.chatList as any);

    const isAi = computed(() => state.value.activeUserId.startsWith('ai-'))

    const updateHead = (item: any) => {
        if (!item.avatar) {
            const { user, isCompany, isSkiller, skiller, company } = useAuthStore()
            const userId = user.userId
            if (userId == item.from) {
                if (isSkiller) {
                    item.avatar = skiller.head || 'https://cos5.51tgqj.com/qf/xcx/image/ai/zixunZ@2x.png'
                } else {
                    item.avatar = company.head || 'https://cos5.51tgqj.com/qf/xcx/image/ai/zixunZ@2x.png'
                }
            } else {
                item.avatar = state.value.activeUser.head || 'https://cos5.51tgqj.com/qf/xcx/image/ai/zixunZ@2x.png'
            }
            if (userId !== item.from && state.value.activeUserId.startsWith('ai-')) {
                item.avatar = '/static/image/msg/defaultHead.png'
            }
        }
    }

    const setChatList = (data: any) => {
        data.forEach((item: any) => {
            updateHead(item)
        })
        state.value.chatList = data;
        state.value.updateId++;
    };
    let start = () => {
        setTimeout(() => {
            heartbeat()
        }, 200)
        if (beatInterval) {
            stop();
        }
        beatInterval = setInterval(heartbeat, 10000);
    };
    let stop = () => {
        clearInterval(beatInterval);
    };
    let heartbeat = () => {
        let body = {} as any;
        body.type = 0;
        uni.sendSocketMessage({
            data: JSON.stringify(body),
            /*success() {
              console.log('----心跳成功----');
            },*/
            fail() {
                connectSocket();
                console.log('心跳错误');
                // 重试登录
                // wxlogin({}).then((token) => {

                // });
            },
        });
    };
    let connectSocket = () => {
        const token = useAuthStore().token;
        if (!token) {
            return;
        }
        uni.connectSocket({
            url: WsPATH + token,
            method: 'GET',
            success: (result) => {
                setTimeout(() => {
                    bindAccount();
                }, 2000);
            },
        });
    };

    let sendChatMessage = (msg: IChatMessage, call?: Function) => {
        console.log('sendChatMessage', msg);
        if (!msg.to) {
            //接收者
            msg.to = state.value.activeUserId;
        }
        msg.from = useAuthStore().state.user?.userId;
        // if (!msg.type) {
        //     msg.type = 2;
        // }
        let body = {} as any;
        body.key = 'msg';
        body.type = 3;
        body.timestamp = new Date().getTime();
        body.data = {};
        // body.data.uid = useAuthStore().state.user.userId;
        // body.data.from = msg.from;
        // body.data.to = msg.to;

        msg.createTime = getCurrentTime();
        body.data.chatMessage = JSON.stringify(msg)
        body.content = JSON.stringify(body);
        uni.sendSocketMessage({
            data: JSON.stringify(body),
            success(result) {
                call && call();
            },
            fail(result) {
                // wxlogin({}).then((token) => {
                //     uni.connectSocket({
                //         url: WsPATH + token,
                //         method: 'GET',
                //     });
                // });
            },
        });
    };

    const bindAccount = () => {
        // 缓存池备份
        let browser = {
            name: 'Other',
            version: '1.0.0',
            appLanguage: 'zh-CN',
        };
        uni.getSystemInfo({
            success: (res: any) => {
                APP_VERSION = res.appVersion;
                browser.version = res.osVersion;
                browser.name = res.osName;
                browser.appLanguage = res.appLanguage;
                if (res.uniPlatform === 'web') {
                    APP_CHANNEL = 'uni-h5';
                    browser.version = res.hostVersion;
                    browser.name = res.hostName;
                } else {
                    if (res.osName === 'android') {
                        APP_CHANNEL = 'uni-android';
                    }
                    if (res.osName === 'ios') {
                        APP_CHANNEL = 'uni-ios';
                    }
                }
            },
        });
        // '绑定账号' APP_CHANNEL
        let deviceId = uni.getStorageSync('deviceId');
        if (deviceId == '' || deviceId == undefined) {
            // deviceId = this.generateUUID();
            uni.setStorageSync('deviceId', deviceId);
        }
        let body = {} as any;
        body.key = 'client_bind';
        body.type = 3;
        body.timestamp = new Date().getTime();
        body.data = {};
        const user = useAuthStore().state.user
        if (!user || !user.userId) {
            return
        }
        body.data.uid = user.userId;
        body.data.channel = APP_CHANNEL;
        body.data.tenantId =import.meta.env.VITE_TENANT_ID;
        body.data.appVersion = APP_VERSION;
        body.data.osVersion = browser.version;
        body.data.packageName = APP_PACKAGE;
        body.data.deviceId = deviceId;
        body.data.deviceName = browser.name;
        body.data.language = browser.appLanguage;
        body.content = JSON.stringify(body);
        uni.sendSocketMessage({
            data: JSON.stringify(body),
            success() {
                // call && call()
            },
            fail(result: any) {
                console.log('fail', result);
            },
        });
    };

    const receiveMsg = (msg: IChatMessage) => {

        // client_bind
        if (msg.type == 4) {
            return;
        }

        if (msg.type == 1) {
            return;
        }

        //TODO 系统消息处理
        if (msg.type! > 10) {
            //系统处理类型消息
            if (msg.type === 11) {
                console.log(msg);
                if (msg.from === state.activeName) {
                    //单次确认
                    for (let i = state.chatList.length - 1; i >= 0; i--) {
                        if (state.chatList[i].id === msg.id) {
                            state.chatList[i].status = 1; //已读
                            break;
                        }
                    }
                }
            } else if (msg.type === 12) {
                //进入聊天窗口-全部确认已读
                if (msg.from === state.activeName) {
                    for (const item of state.chatList) {
                        item.status = 1; //已读
                    }
                }
            }
            return;
        }
        //TODO 群组消息处理
        if (msg.code === 6) {
            //非本人消息才处理
            if (msg.from !== useAuthStore().skiller.name) {
                // 群消息正在打开
                if (msg.to === state.activeName) {
                    //当前聊天窗口打开的消息
                    state.chatList.push(msg);
                    state.updateId++;
                    //发送消息确认群已读
                    uni.sendSocketMessage({
                        data: '@' + msg.to,
                    });
                } else {
                    msg.from = msg.to; //设置来源为组id
                    msg.avatar = msg.groupAvatar;
                    msg.nickName = msg.groupNickName;
                    msgCheckList(state, msg);
                }
            } else {
                //当前聊天窗口打开的消息
                if (msg.to === state.activeName) {
                    state.chatList.push(msg);
                    state.updateId++;
                }
            }
            return;
        }
        console.log(state.value,'测试数据state');
        //TODO 非群消息处理
        if (msg.to === state.activeName || msg.from === state.activeName) {
            //当前聊天窗口打开的消息
            const chatItem = JSON.parse(JSON.parse(msg.content).content)
            updateHead(chatItem)
            state.value.chatList.push(chatItem);
            state.value.updateId++;

            //消息是否就是实时接收到页面
            // if (msg.from === state.activeName) {
            //     //发送消息确认已读
            //     uni.sendSocketMessage({
            //         data: msg.id + ',' + msg.from,
            //     });
            // }
        } else {
            //不在聊天窗口打开的消息且不是自己的消息
            if (msg.from !== useAuthStore().skiller.name) {
                msgCheckList(state, msg);
            }
        }
    };

    const msgCheckList = (state: IChat, msg: IChatMessage) => { };

    const setActive = (userId: string) => {
        state.value.activeUserId = userId
    }
    const authStore = useAuthStore();
    const addAiChat = (msg: IChatMessage) => {
        updateHead(msg)
        state.value.chatList.push(msg);
    }
    const data = ref({
        isExit: false, // 是否已退出
        audioTip: null,
        reconnecting: false // 正在重连标志
    })
    const init=()=> {

        if (!authStore.isLogin){
            // router.push({
            //     name: 'codeLogin'
            // });
            console.log('用户未登录')
            setTimeout(init, 5000);
            return
        }
        console.log('开始往服务器进行请求')
        data.value.isExit = false;
        // 加载数据
        store.dispatch("load").then(() => {
            // 初始化websocket
            console.log('数据加载完成')
            initWebSocket();
        }).catch((e) => {
            console.log('出现问题：',e);
            exit();
        })
    }

    function initWebSocket() {
        const authStore = useAuthStore();
        wsApi.init();
        wsApi.connect(import.meta.env.VITE_BASE_WSS_WQDDG, authStore.state.token);
        wsApi.onConnect(() => {
            // 重连成功提示
            if (data.value.reconnecting) {
                data.value.reconnecting = false;
                // 调整为控制台输出
                console.log('已重新连接')
                // uni.showToast({
                //     title: "已重新连接",
                //     icon: 'none'
                // })
            }
            store.commit("loadingPrivateMsg", true)
            // 加载离线消息
            pullPrivateOfflineMessage(store.state.chatStore.privateMsgMaxId);
            // pullGroupOfflineMessage(store.state.chatStore.groupMsgMaxId);
        });
        wsApi.onMessage((cmd, msgInfo) => {
            console.log(cmd,msgInfo,'获取socket消息')
            if (cmd == 2) {
                // 异地登录，强制下线
                uni.showModal({
                    content: '您已在其他地方登陆，将被强制下线',
                    showCancel: false,
                })
                exit();
            } else if (cmd == 3) {
                // 私聊消息
                handlePrivateMessage(msgInfo);
            } else if (cmd == 4) {
                // 群聊消息
                //handleGroupMessage(msgInfo);
            } else if (cmd == 5) {
                // 系统消息
                handleSystemMessage(msgInfo);
            }
        });
        wsApi.onClose((res) => {
            console.log("ws断开", res);
            // 重新连接
            reconnectWs();

        })
    }

    function pullPrivateOfflineMessage(minId) {
        store.commit("loadingPrivateMsg", true)
        personPullOfflineMessage(minId).catch(()=>{
            store.commit("loadingPrivateMsg", false)
        })
        // http({
        //   url: "/message/private/pullOfflineMessage?minId=" + minId,
        //   method: 'GET'
        // }).catch(() => {
        //   store.commit("loadingPrivateMsg", false)
        // })
    }

    function pullGroupOfflineMessage(minId) {
        store.commit("loadingGroupMsg", true)
        // http({
        //   url: "/message/group/pullOfflineMessage?minId=" + minId,
        //   method: 'GET'
        // }).catch(() => {
        //   store.commit("loadingGroupMsg", false)
        // })
    }

    function handlePrivateMessage(msg) {
        console.log("获取消息回执："+msg)
        // 消息加载标志
        if (msg.type == enums.MESSAGE_TYPE.LOADING) {
            console.log('获取最新的消息：')
            store.commit("loadingPrivateMsg", JSON.parse(msg.content))
            return;
        }
        // 消息已读处理，清空已读数量
        if (msg.type == enums.MESSAGE_TYPE.READED) {
            store.commit("resetUnreadCount", {
                type: 'PRIVATE',
                targetId: msg.recvUserId
            })
            return;
        }
        // 消息回执处理,改消息状态为已读
        if (msg.type == enums.MESSAGE_TYPE.RECEIPT) {
            store.commit("readedMessage", {
                friendId: msg.sendUserId
            })
            return;
        }
        const authStore = useAuthStore();
        // 标记这条消息是不是自己发的
        msg.selfSend = msg.sendUserId == authStore.user.userId;
        // 好友id
        let friendId = msg.selfSend ? msg.recvUserId : msg.sendUserId;
        loadFriendInfo(friendId).then((friend) => {
            console.log(msg,'数据获取',msg.sendUserId,authStore.user.userId)
            insertPrivateMessage(friend, msg);
        })
    }

    function insertPrivateMessage(friend, msg) {
        // 单人视频信令
        if (msgType.isRtcPrivate(msg.type)) {
            // #ifdef MP-WEIXIN
            // 小程序不支持音视频
            return;
            // #endif
            // 被呼叫，弹出视频页面
            let delayTime = 100;
            if (msg.type == enums.MESSAGE_TYPE.RTC_CALL_VOICE ||
                msg.type == enums.MESSAGE_TYPE.RTC_CALL_VIDEO) {
                let mode = msg.type == enums.MESSAGE_TYPE.RTC_CALL_VIDEO ? "video" : "voice";
                let pages = getCurrentPages();
                let curPage = pages[pages.length - 1].route;
                if (curPage != "pages/chat/chat-private-video") {
                    const friendInfo = encodeURIComponent(JSON.stringify(friend));
                    uni.navigateTo({
                        url: `/pages/chat/chat-private-video?mode=${mode}&friend=${friendInfo}&isHost=false`
                    })
                    delayTime = 500;
                }
            }
            setTimeout(() => {
                uni.$emit('WS_RTC_PRIVATE', msg);
            }, delayTime)
            return;
        }
        let chatInfo = {
            type: 'PRIVATE',
            targetId: friend.chattedWithUserId,
            showName: friend.chattedWithName,
            briefIntroduction: friend.briefIntroduction,
            headImage: friend.chattedWithImage,
            workId: friend.workId,
        };
        // 打开会话
        store.commit("openChat", chatInfo);
        // 插入消息
        store.commit("insertMessage", msg);
        // 播放提示音
        playAudioTip();

    }

    function handleGroupMessage(msg) {
        // 消息加载标志
        if (msg.type == enums.MESSAGE_TYPE.LOADING) {
            store.commit("loadingGroupMsg", JSON.parse(msg.content))
            return;
        }
        // 消息已读处理
        if (msg.type == enums.MESSAGE_TYPE.READED) {
            // 我已读对方的消息，清空已读数量
            let chatInfo = {
                type: 'GROUP',
                targetId: msg.groupId
            }
            store.commit("resetUnreadCount", chatInfo)
            return;
        }
        // 消息回执处理
        if (msg.type == enums.MESSAGE_TYPE.RECEIPT) {
            // 更新消息已读人数
            let msgInfo = {
                id: msg.id,
                groupId: msg.groupId,
                readedCount: msg.readedCount,
                receiptOk: msg.receiptOk
            };
            store.commit("updateMessage", msgInfo)
            return;
        }
        // 标记这条消息是不是自己发的
        msg.selfSend = msg.sendId == authStore.user.userId;
        // loadGroupInfo(msg.groupId).then((group) => {
        //   // 插入群聊消息
        //   insertGroupMessage(group, msg);
        // })
    }

    function handleSystemMessage(msg) {
        if (msg.type == enums.MESSAGE_TYPE.USER_BANNED) {
            // 用户被封禁
            wsApi.close(3099);
            uni.showModal({
                content: '您的账号已被管理员封禁，原因:' + msg.content,
                showCancel: false,
            })
            exit();
        }
    }

    function insertGroupMessage(group, msg) {
        // 群视频信令
        if (msgType.isRtcGroup(msg.type)) {
            // #ifdef MP-WEIXIN
            // 小程序不支持音视频
            return;
            // #endif
            // 被呼叫，弹出视频页面
            let delayTime = 100;
            if (msg.type == enums.MESSAGE_TYPE.RTC_GROUP_SETUP) {
                let pages = getCurrentPages();
                let curPage = pages[pages.length - 1].route;
                if (curPage != "pages/chat/chat-group-video") {
                    const userInfos = encodeURIComponent(msg.content);
                    const inviterId = msg.sendId;
                    const groupId = msg.groupId
                    uni.navigateTo({
                        url: `/pages/chat/chat-group-video?groupId=${groupId}&isHost=false
									&inviterId=${inviterId}&userInfos=${userInfos}`
                    })
                    delayTime = 500;
                }
            }
            // 消息转发到chat-group-video页面进行处理
            setTimeout(() => {
                uni.$emit('WS_RTC_GROUP', msg);
            }, delayTime)
            return;
        }

        let chatInfo = {
            type: 'GROUP',
            targetId: group.id,
            showName: group.showGroupName,
            headImage: group.headImageThumb
        };
        // 打开会话
        store.commit("openChat", chatInfo);
        // 插入消息
        store.commit("insertMessage", msg);
        // 播放提示音
        playAudioTip();
    }

    function loadFriendInfo(id) {
        return new Promise((resolve, reject) => {
            let friend = store.getters.findFriend(id);
            if (friend) {
                resolve(friend);
            } else {
                find(id).then(res=>{
                    console.log('获取数据loadFriendInfo',res.data)
                    store.commit("addFriend", res.data);
                    resolve(res.data)
                })
                // http({
                //   url: `/friend/find/${id}`,
                //   method: 'GET'
                // }).then((friend) => {
                //   store.commit("addFriend", friend);
                //   resolve(friend)
                // })
            }
        });
    }

// function loadGroupInfo(id) {
//   return new Promise((resolve, reject) => {
//     // let group = store.state.groupStore.groups.find((g) => g.id == id);
//     // if (group) {
//     //   resolve(group);
//     // } else {
//     //   http({
//     //     url: `/group/find/${id}`,
//     //     method: 'GET'
//     //   }).then((group) => {
//     //     resolve(group)
//     //     store.commit("addGroup", group);
//     //   })
//     // }
//   });
// }

    const exit=()=> {
        console.log('出现问题 需要退出')
        data.value.isExit = true;
        wsApi.close(3099);
        uni.removeStorageSync("loginInfo");
        // router.push({
        //     name: 'codeLogin'
        // });
        authStore.webLogout();
        store.dispatch("unload");
    }

    function playAudioTip() {
        // 音频播放无法成功
        // data.audioTip = uni.createInnerAudioContext();
        // data.audioTip.src =  "/static/audio/tip.wav";
        // data.audioTip.play();
    }

    function isExpired(loginInfo) {
        if (!loginInfo || !loginInfo.expireTime) {
            return true;
        }
        return loginInfo.expireTime < new Date().getTime();
    }

    function reconnectWs() {
        // 已退出则不再重连
        if (data.value.isExit) {
            return;
        }
        // 记录标志
        data.value.reconnecting = true;
        // 重新加载一次个人信息，目的是为了保证网络已经正常且token有效
        reloadUserInfo().then((userInfo) => {
            // uni.showToast({
            //     title: '连接已断开，尝试重新连接...',
            //     icon: 'none',
            // })
            store.commit("setUserInfo", userInfo);
            // 重新连接
            let loginInfo = uni.getStorageSync("loginInfo")
            const authStore = useAuthStore();
            wsApi.reconnect(import.meta.env.VITE_BASE_WSS_WQDDG, authStore.state.token);
        }).catch(() => {
            // 5s后重试
            setTimeout(() => {
                reconnectWs();
            }, 5000)
        })
    }

    function reloadUserInfo() {
        return findChatPersonnels()
    }


    return {
        state,
        chatList,
        setChatList,
        addAiChat,
        setActive,
        sendChatMessage,
        start,
        receiveMsg,
        isAi,
        aiStep,
        genderVal,
        skillsVal,
        init,
        exit
    };
});
