/* tslint:disable */
/* eslint-disable */

import { RestApplicationClient, type HttpClient, R } from './backend.api';

import { queryParams } from '@climblee/uv-ui/libs/function/index.js';

export const baseUrl = import.meta.env.VITE_BASE_URL;

class AxiosHttpClient implements HttpClient {
  
    request<R>(requestConfig: { method: string; url: string; queryParams?: any; data?: any; copyFn?: (data: R) => R; }): R {

      if (requestConfig.queryParams) {
        Object.keys(requestConfig.queryParams).forEach((item) => {
          const dataItem = requestConfig.queryParams[item];
          if (dataItem === null || dataItem === undefined || dataItem === '') {
            delete requestConfig.queryParams[item];
          }
        });
      }

      const options: UniNamespace.RequestOptions = {
        url:requestConfig.url + queryParams(requestConfig.queryParams),
        method: requestConfig.method,
        data: requestConfig.data,
      }

      if (options.url === undefined || !(options.url.startsWith("http") || options.url.startsWith("https"))) {
        options.url = baseUrl + options.url;
        if (uni.getStorageSync("token")) {
          options.header = {
            ...options.header,
            Authorization: "Bearer " + uni.getStorageSync("token")
          };
        }
      }
      options.timeout = 88000;
      options.data = requestConfig.data
      const data = options.data;
      if (data) {
        Object.keys(data).forEach((item) => {
          const dataItem = data[item];
          if (dataItem === null || dataItem === undefined) {
            delete data[item];
          }
        });
      }
      return new Promise<R>((reslove, reject) => {
        uni.request({
          ...options,
          success: (res) => {
            if (res.statusCode >= 200 && res.statusCode < 300) {
              const data = res.data as R<T>;
              if (data.code === 401) {
                // router.push({
                //     name: loginPageName,
                // })
                // reject({});
                // uni.showToast({
                //     title: '用户未授权，请重新登录',
                //     icon: 'none',
                // });
              } else {
              }
              return reslove(res.data as R<T>);
            } else if (res.statusCode === 401) {
              uni.showToast({
                title: "用户未授权，请重新登录",
                icon: "none"
              });
            } else {
              // uni.showToast({
              //     title: (res.data as R<T>).msg,
              //     icon: 'none',
              // });
            }
            reject(res);
          },
          fail: (err) => {
            uni.showToast({
              title: "网络异常，请稍等重试",
              icon: "none"
            });
            reject(err);
          }
        });
      }) as R;
    }
}

const apiClient = new RestApplicationClient(new AxiosHttpClient())

export default apiClient
