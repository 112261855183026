import createHttp from '@/uni_modules/base-plugin/utils/request';

export const findChatPersonnels = () => {
    return createHttp.get('/chatPersonnel/findChatPersonnels', {pageNum:1,pageSize:1000});
};

export const addChatPersonnels = (data:any) => {
    return createHttp.post('/chatPersonnel/addChatPersonnels', data);
};
export const OnlineTerminals = (userids:string) => {
    return createHttp.get('/chatPersonnel/onlineTerminals', {userIds:userids});
};

export const find = (chattedWithUserId:string) => {
    return createHttp.get('/chatPersonnel/find', {chattedWithUserId:chattedWithUserId});
};

export const findPersionnel = (chattedWithUserId:string,workId:string) => {
    return createHttp.get('/chatPersonnel/findPersionnel', {chattedWithUserId:chattedWithUserId,workId:workId});
};

export const findChatWork = (workId:string) => {
    return createHttp.get('/chatPersonnel/findChatWork', {workId:workId});
};
