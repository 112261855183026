
import {useAuthStore} from "../../base-plugin/state/modules/auth";
import {USER_STATE} from "../../base-plugin/state/modules/enums";
export default {
	state: {
		userInfo: {},
		config:{
			webrtc:{}
		},
		state: USER_STATE.FREE
	},

	mutations: {
		setUserInfo(state, userInfo) {
			// 使用深拷贝方式，否则小程序页面不刷新
			Object.assign(state.userInfo, userInfo);
		},
		setUserState(state, userState) {
			state.state = userState;
		},
		clear(state){
			state.userInfo = {};
			state.state = USER_STATE.FREE;
		}
	},
	actions:{
		loadUser(context){
			return new Promise((resolve, reject) => {
				const authStore = useAuthStore();
				console.log(authStore.state,'测试服')
				context.commit("setUserInfo",authStore.state.user);
				resolve();
				// http({
				// 	url: '/user/self',
				// 	method: 'GET'
				// }).then((userInfo) => {
				// 	console.log(userInfo)
				// 	context.commit("setUserInfo",userInfo);
				// 	resolve();
				// }).catch((res)=>{
				// 	reject(res);
				// });
			})
		}
	}
}
