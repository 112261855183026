/*
 * @Author: zhouyankai
 * @Date: 2023-07-26
 * @LastEditTime: 2023-04-26
 * @LastEditors: zhouyankai
 * @Description:有关权限的pinia
 */
import { defineStore, storeToRefs } from "pinia";

import { wxlogin, appCodeLogin, refreshToken, checkUserToken } from "@/uni_modules/base-plugin/api/common/login/login";
import apiClient from "@/uni_modules/base-plugin/api/request";
import { useChatStore, type IUser } from "./chat";
import { computed, nextTick, ref, type Ref } from "vue";
import { getSkillerSelfDetail, skillUpdate } from "@/uni_modules/base-plugin/api/common/skill/skill";
import { getClerkSiteIds } from "@/uni_modules/base-plugin/api/common/clerk/clerk";
import { getCompanyById } from "@/uni_modules/base-plugin/api/common/company/company";
import { getOddStationDetail } from "@/uni_modules/base-plugin/api/oddStation/oddStation";
import { refreshCheck, switchType } from "@/uni_modules/base-plugin/api/login/login";
import { getMsgByNew } from "@/uni_modules/base-plugin/api/msg/msg";
import { findOneSchool } from "@/uni_modules/base-plugin/api/school/school";
import router from "@/router";
import { loginPageName } from "@/uni_modules/base-plugin/utils/dict";
import { getEconomyMan } from "@/uni_modules/base-plugin/api/economyMan/economyMan";
import { getZgmCommunityDetail } from "@/uni_modules/base-plugin/api/zgmCommunity/zgmCommunity";
import { queryByGroup } from "@/uni_modules/base-plugin/api/theme/theme";
import { R } from "@/uni_modules/base-plugin/types/global";
import { baseUrl } from "@/uni_modules/base-plugin/utils/request";
import { getClerkDetailById } from "@/uni_modules/base-plugin/api/clerk/clerk";
import { authorizeOpenId } from "@/uni_modules/base-plugin/api/wechat/wechat";

interface AuthState {
  token?: string;

  user: User;

  skillerInfo: SkillerInfo;

  company: any;

  school: any;

  siteids: [];

  economyMan: any;

  oddStation: any;

  zgmCommunity: any;

  clerk: any;

  theme: any;

  town: "";

  openId:string;
}

export interface User {
  code: boolean;
  isFirst: string;
  phone: string;
  userId: string;
  token: string;
  skillerId: string;
  type: string;
  openId: string;
  clerkId: string;
  companyId: string;
  siteId: string;
  oddStationId: string;
  typeList: string[];
  roles: string[];
  permissions: string[];
  clerkRoleType: string;
  economyId: string;
  zgmCommunityId: string;
}

export interface UserInterview {
  applyCount: number;
  interviewCount: number;
  phoneCount: number;
}

export interface SkillerInfo {
  skiller: Skiller;
  userInterview: UserInterview;
  skillerWorkExperience: any[];
}

export interface Skiller {
  id: string;
  head: string;
  name: string;
  siteId: string;
  skills: string;
  skillsIds: string;
  skills2: string;
  skillsIds2: string;
  birth: string;
  openShow: string;
  sex: string;
  hopeSalary: string;
  jobState: string;
  education: string;
  addr1: string;
  addr2: string;
  permanentJob: string;
  workingHours: string;
  workingTime: string;
  weekWorkingDays: string;
  authenticationState: string;
}

console.log("auth store init");

export const useAuthStore = defineStore("auth", () => {

  const state = ref<AuthState>({
    token: uni.getStorageSync("token"),
    user: uni.getStorageSync("user"),
    skillerInfo:
      uni.getStorageSync("skillerInfo") ||
      ({
        skiller: {},
        skillerWorkExperience: [],
        userInterview: {}
      } as SkillerInfo),
    siteids: uni.getStorageSync("siteids") || [],
    company: uni.getStorageSync("company"),
    school: uni.getStorageSync("school"),
    economyMan: uni.getStorageSync("economyMan"),
    oddStation: uni.getStorageSync("oddStation"),
    zgmCommunity: uni.getStorageSync("zgmCommunity"),
    clerk: uni.getStorageSync("clerk"),
    theme: uni.getStorageSync("theme"),
    openId: uni.getStorageSync("openId"),
    town: ""
  });

  let setLoginOutListener;

  // #ifdef APP
  const workModule = uni.requireNativePlugin("WorkModule");
  if (workModule) {
    workModule.initModule({ defaultHost: baseUrl }, (data: any) => {
    });
    setLoginOutListener = () => {
      workModule.setLogoutCallback({}, () => {
        logout();
      });
    };
    setLoginOutListener();
  }
  // #endif

  const theme = computed(() => state.value.theme);

  const openId: Ref<string> = computed(() => state.value.openId);
  //
  // queryByGroup({ group: "theme" }).then((data: R<any>) => {
  //   const theme = data.data;
  //   uni.setStorageSync("theme", theme);
  //   state.value.theme = theme;
  //
  //   // #ifdef MP-WEIXIN
  //   uni.login({
  //     provider: 'weixin',
  //     onlyAuthorize: true, // 微信登录仅请求授权认证
  //     success: async (event) => {
  //       const code = event.code;
  //       const res = await authorizeOpenId({ code: code }) as any;
  //       const openId = res.data.openid
  //       uni.setStorageSync("openId", openId);
  //       state.value.openId = openId;
  //       const pages = getCurrentPages();
  //       uni.request({
  //         data: {
  //           openId: openId,
  //           skillerId: skiller.value.id,
  //           pageUrl: pages[pages.length - 1].route
  //         },
  //         method: "POST",
  //         url: "/useractive/add",
  //         success: (res) => {
  //         }
  //       });
  //     },
  //   });
  //   // #endif
  //
  // });

  const isLogin = computed(() => !!state.value?.token);
  const token = computed(() => state.value.token);
  const user: Ref<User> = computed(() => state.value.user);
  const economyMan: Ref<User> = computed(() => state.value.economyMan);
  const oddStation: Ref<User> = computed(() => state.value.oddStation);
  const skillerInfo = computed(() => state.value.skillerInfo);
  const skiller = computed(() => state.value.skillerInfo?.skiller || ({} as Skiller));
  const company = computed(() => state.value.company);
  const clerk = computed(() => state.value.clerk);
  const zgmCommunity = computed(() => state.value.zgmCommunity);
  const town = computed(() => state.value.town);

  const tabBarRedDot = ref({})

  const selectedSkillList = computed(() => {
    const skiller = state.value.skillerInfo?.skiller;
    const skillsIds = skiller?.skillsIds?.split(",");
    const skills = skiller?.skills?.split(",");

    if (!skills || (skills.length > 0 && skills[0].trimEnd().length === 0)) {
      return [];
    }

    const skillList = skillsIds?.map((item, index) => {
      return {
        id: skillsIds[index],
        name: skills[index]
      };
    });
    return skillList || [];
  });

  const selectedSkill2List = computed(() => {
    const skiller = state.value.skillerInfo?.skiller;
    const skillsIds2 = skiller?.skillsIds2?.split(",");
    const skills2 = skiller?.skills2?.split(",");

    if (!skills2 || (skills2.length > 0 && skills2[0].trimEnd().length === 0)) {
      return [];
    }

    const skillList = skillsIds2.map((item, index) => {
      return {
        id: skillsIds2[index],
        name: skills2[index]
      };
    });
    return skillList || [];
  });

  //是企业
  const isCompany = computed(() => state.value?.user?.type === "1");

  //是求职者
  const isSkiller = computed(() => state.value?.user?.type === "2");

  //是求职者或未登录
  const isSkillerOrNotLogin = computed(() => isSkiller.value || !isLogin.value);

  //是一站四员
  const isClerk = computed(() => state.value?.user?.type === "3");

  //是一站四员
  const isSchool = computed(() => state.value?.user?.type === "4");

  //是零工站点 站长
  const isOddStation = computed(() => state.value?.user?.type === "5");

  //是一站四员 指导员
  const isClerkGuidance = computed(() => state.value?.user?.clerkRoleType === "1");

  //是一站四员 联络员
  const isClerkLiaison = computed(() => state.value?.user?.clerkRoleType === "2");

  //是一站四员 服务员
  const isClerkEmploy = computed(() => state.value?.user?.clerkRoleType === "3");

  //是一站四员 宣传员
  const isClerkPublicize = computed(() => state.value?.user?.clerkRoleType === "4");

  //是一站四员 站长
  const isClerkStation = computed(() => state.value?.user?.clerkRoleType === "5");

  //是经纪人
  const isEconomyMan = computed(() => state.value?.user?.type === "7");

  //是社区人员
  const isZgmCommunity = computed(() => state.value?.user?.type === "8");

  const containsAny = (array: any[], values: any[]) => {
    if (!array || array.length < 2) return false;
    for (const value of values) {
      if (array.includes(value)) {
        return true;
      }
    }
    return false;
  };

  //有多重身份
  const hasMultiType = computed(() => isLogin.value && containsAny(state.value?.user?.typeList, ["1", "3", "5"]));

  const hasMenuPermission = computed(() => {
    return (menuPermission: string) => {
      const permissions = state.value.user.permissions;
      return permissions && permissions.indexOf(menuPermission) > -1;
    };
  });

  const siteids: Ref<[]> = computed(() => state.value.siteids);

  const isUserDetailComplete = computed(
    () =>
      !(!skiller.value?.name || skiller.value?.sex == null || !skiller.value?.birth || !skiller.value?.education)
  );

  const isOddAuthentication = computed(
    () =>
      !(
        !skiller.value?.permanentJob ||
        skiller.value?.workingHours == null ||
        !skiller.value?.workingTime ||
        !skiller.value?.weekWorkingDays ||
        !skiller.value?.skills2 ||
        !skiller.value?.skillsIds2
      )
  );

  const isEmploymentInformationComplete = computed(
    () => !(!skiller.value.jobState || !skiller.value.skills || !skiller.value.hopeSalary)
  );

  const isSkillerWorkExperience = computed(() => skillerInfo.value?.skillerWorkExperience?.length > 0);

  const isAuthentication = computed(() => {
    return (
      skiller.value.authenticationState ||
      (isCompany.value && company.value.examineState == "1") ||
      isOddStation.value
    );
  });

  const hastSiteId = computed(() => {
    const siteId = skillerInfo.value.skiller?.siteId;
    return !!siteId;
  });

  const addSkillerWorkExperience = (data: any) => {
    state.value.skillerInfo.skillerWorkExperience = [
      ...(state.value.skillerInfo?.skillerWorkExperience || []),
      data
    ];
    uni.setStorageSync("skillerInfo", state.value.skillerInfo);
  };

  const updateSkillerWorkExperience = (data: any) => {
    const index = state.value.skillerInfo.skillerWorkExperience.findIndex((item) => item.id === data.id);
    if (index > -1) {
      state.value.skillerInfo.skillerWorkExperience[index] = data;
    }
  };

  const setSkillerInfo = (data: any) => {
    state.value.skillerInfo = data.data;
    state.value.town = data.data.skiller.village?.split(",")[0];
    uni.setStorageSync("skillerInfo", data.data);
  };

  const setSiteIds = (data: any) => {
    state.value.siteids = data.data;
    uni.setStorageSync("siteids", data.data);
  };

  const setCompany = (data: any) => {
    state.value.company = data.data;
    uni.setStorageSync("company", data.data);
  };

  const setOddStation = (data: any) => {
    state.value.oddStation = data.data;
    uni.setStorageSync("oddStation", data.data);
  };

  const setEconomyMan = (data: any) => {
    state.value.economyMan = data.data;
    uni.setStorageSync("economyMan", data.data);
  };

  const setSchool = (data: any) => {
    state.value.school = data.data;
    uni.setStorageSync("school", data.data);
  };

  const setZgmCommunity = (data: any) => {
    state.value.zgmCommunity = data.data;
    uni.setStorageSync("zgmCommunity", data.data);
  };

  const setClerk = (data: any) => {
    state.value.clerk = data.data;
    state.value.town = data.data.address;
    uni.setStorageSync("clerk", data.data);
  };

  const updateSkillerSkills = async (skillSelectedList: any[]) => {
    const skills = skillSelectedList.map((r) => r.item.name).join(",");
    const skillsIds = skillSelectedList.map((r) => r.item.id).join(",");
    const params = { id: user.value.skillerId, skills: skills, skillsIds: skillsIds };
    await skillUpdate(params);
    skillerInfo.value.skiller.skills = skills;
    skillerInfo.value.skiller.skillsIds = skillsIds;
    uni.setStorageSync("skillerInfo", skillerInfo.value);
  };

  const updateOddSkillerSkills = async (skillSelectedList: any[]) => {
    const skills = skillSelectedList.map((r) => r.item.name).join(",");
    const skillsIds = skillSelectedList.map((r) => r.item.id).join(",");
    const params = { id: user.value.skillerId, skills2: skills, skillsIds2: skillsIds };
    await skillUpdate(params);
    skillerInfo.value.skiller.skills2 = skills;
    skillerInfo.value.skiller.skillsIds2 = skillsIds;
    uni.setStorageSync("skillerInfo", skillerInfo.value);
  };

  const reqRefreshCheck = (form: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      form.clerkId = uni.getStorageSync("clerkId");
      form.oddStationId = uni.getStorageSync("oddStationId");
      form.siteId = uni.getStorageSync("siteId");
      refreshCheck(form)
        .then((data: any) => {
          afterLogin(data)
            ?.then(() => {
              resolve({});
            })
            .catch(() => {
              reject();
            });
        })
        .catch(() => {
          reject();
        });
    });
  };

  const login = (form: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      form.clerkId = uni.getStorageSync("clerkId");
      form.oddStationId = uni.getStorageSync("oddStationId");
      form.siteId = uni.getStorageSync("siteId");
      wxlogin(form)
        .then((data: any) => {
          afterLogin(data)
            ?.then(() => {
              resolve({});
            })
            .catch(() => {
              reject();
            });
        })
        .catch(() => {
          reject();
        });
    });
  };

  const codeLogin = (form: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      form.clerkId = uni.getStorageSync("clerkId");
      form.oddStationId = uni.getStorageSync("oddStationId");
      form.siteId = uni.getStorageSync("siteId");
      apiClient.signIn(form)
        .then((res: any) => {
          afterLogin(res)
            .then(() => {
              resolve({});
            })
            .catch(() => {
              reject();
            });
        })
        .catch(() => {
          reject();
        });
    });
  };

  const newToken = (form: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      refreshToken(form)
        .then((data: any) => {
          afterLogin(data)
            .then(() => {
              resolve({});
            })
            .catch(() => {
              reject();
            });
        })
        .catch(() => {
          reject();
        });
    });
  };

  const checkToken = (token: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      checkUserToken(token).then((data: any) => {
        if (data.code != 200) {
          logout();
          uni.showModal({
            title: "提示",
            content: "登录失效，点击确定重新登录",
            success: (res) => {
              if (res.confirm) {
                router.push({
                  name: loginPageName
                });
              }
            }
          });
        }
      });
    });
  };

  const userSwitchType = (newType: string) => {
    switchType({ newType: newType }).then((res) => {
      afterLogin(res);
    });
  };

  const msgList = ref([{}, {}]);

  const handleMsgByNew = () => {
    if (isLogin.value) {
      getMsgByNew()
        .then((res) => {
          const pages = getCurrentPages();
          if (!pages || !pages.length) {
            return;
          }
          const route = pages[pages.length - 1].route;
          if (route.indexOf("msg/msg") > -1) {
            const data = res.data
            msgList.value = res.data;
            const msg1 = data[0]?.num;
            const msg2 = data[1]?.num;
            if (msg1 + msg2 > 0) {
              tabBarRedDot.value = {
                index: 3,
                text: msg1 + msg2,
              }
            } else {
              tabBarRedDot.value = {
                index: 3,
                text: 0,
              }
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const afterLogin = (res: any): Promise<any> => {
    uni.removeStorageSync("clerkId");
    uni.getStorageSync("oddStationId");
    uni.removeStorageSync("siteId");
    const { token } = res.data;
    state.value.token = token;
    state.value.user = res.data as any;
    uni.setStorageSync("token", token);
    uni.setStorageSync("user", res.data);
    // handleMsgByNew();
    const type = state.value.user.type;

    if (type == "2") {
      // 是工匠
      return getSkillerSelfDetail().then((data: any) => {
        setSkillerInfo(data);
        // useChatStore().start();
      });
    } else if (type == "3") {
      const { clerkId } = state.value.user;
      return getClerkSiteIds({ id: clerkId }).then((data: any) => {
        setSiteIds(data);
        // useChatStore().start();
      });
    } else if (type == "1") {
      const { companyId } = res.data;
      return getCompanyById({ id: companyId }).then((data: any) => {
        setCompany(data);
        // useChatStore().start();
      });
    } else if (type == "5") {
      const { oddStationId } = res.data;
      return getOddStationDetail({ id: oddStationId }).then((data: any) => {
        setOddStation(data);
        // useChatStore().start();
      });
    } else if (type == "4") {
      const { schoolId } = res.data;
      return findOneSchool({ schoolId: schoolId }).then((data: any) => {
        setSchool(data);
      });
    } else if (type == "7") {
      return getEconomyMan().then((data: any) => {
        setEconomyMan(data);
        useChatStore().start();
      });
    }
    return new Promise((resolve, reject) => {
      console.error("未知用户类型");
      resolve({});
    });
  };

  const getSkillerDetail = () => {
    if (!isSkiller.value) {
      return Promise.resolve({});
    }
    return getSkillerSelfDetail().then((data) => {
      setSkillerInfo(data);
      return data;
    });
  };

  const getEconomyDetail = () => {
    return getEconomyMan().then((data) => {
      setEconomyMan(data);
      return data;
    });
  };

  const getCompanyDetail = () => {
    return getCompanyById({ id: state.value.company.id }).then((data) => {
      setCompany(data);
      return data;
    });
  };

  const getZgmCommunityDetail1 = () => {
    return getZgmCommunityDetail({ id: state.value.user.zgmCommunityId }).then((data) => {
      setZgmCommunity(data);
      return data;
    });
  };

  const getClerkDetail = () => {
    return getClerkDetailById({ id: state.value.user.clerkId }).then((data) => {
      setClerk(data);
      return data;
    });
  };

  const logout = () => {
    // #ifdef APP
    uni.removeStorageSync("token");
    uni.removeStorageSync("user");
    uni.removeStorageSync("skillerInfo");
    uni.removeStorageSync("company");
    uni.removeStorageSync("siteids");
    uni.removeStorageSync("economyMan");
    uni.removeStorageSync("oddStation");
    uni.removeStorageSync("zgmCommunity");
    nextTick(() => {
      if (state.value.skillerInfo) {
        state.value.skillerInfo.skiller = {} as Skiller;
        state.value.skillerInfo.userInterview = {} as UserInterview;
      }
      state.value.siteids = [];
      state.value.user = {} as User;
      state.value.token = null;
      state.value.town = "";
      router.replaceAll({ name: "home" });
      workModule.finishActivity({}, () => {
        setLoginOutListener();
      });
    });
    // #endif
    // #ifndef APP
    useChatStore().exit();
    // #endif
  };

  const webLogout = () => {
    uni.removeStorageSync("token");
    uni.removeStorageSync("user");
    uni.removeStorageSync("skillerInfo");
    uni.removeStorageSync("company");
    uni.removeStorageSync("siteids");
    uni.removeStorageSync("economyMan");
    uni.removeStorageSync("oddStation");
    uni.removeStorageSync("zgmCommunity");
    nextTick(() => {
      if (state.value.skillerInfo) {
        state.value.skillerInfo.skiller = {} as Skiller;
        state.value.skillerInfo.userInterview = {} as UserInterview;
      }
      state.value.siteids = [];
      state.value.user = {} as User;
      state.value.token = null;
      state.value.town = "";
      // #ifdef APP-ANDROID
      workModule.finishActivity({}, () => {
      });
      // #endif
    });
    useChatStore().init();
  };

  const updateOpenId = (openId: string) => {
    // alert('更新openId' + openId);
    user.value.openId = openId;
    // alert('新的user' + JSON.stringify(user.value));
    uni.setStorageSync("user", user.value);
  };

  return {
    isLogin,
    isClerk,
    isClerkGuidance,
    isClerkLiaison,
    isClerkEmploy,
    isClerkStation,
    isClerkPublicize,
    isEconomyMan,
    economyMan,
    isZgmCommunity,
    isOddStation,
    isCompany,
    isSchool,
    isSkiller,
    user,
    clerk,
    town,
    zgmCommunity,
    oddStation,
    hasMultiType,
    state,
    login,
    codeLogin,
    updateOpenId,
    skillerInfo,
    skiller,
    token,
    logout,
    isUserDetailComplete,
    isEmploymentInformationComplete,
    isSkillerWorkExperience,
    setSkillerInfo,
    updateOddSkillerSkills,
    siteids,
    company,
    isOddAuthentication,
    updateSkillerSkills,
    getSkillerDetail,
    getCompanyDetail,
    getEconomyDetail,
    getZgmCommunityDetail1,
    reqRefreshCheck,
    newToken,
    addSkillerWorkExperience,
    userSwitchType,
    selectedSkillList,
    selectedSkill2List,
    isAuthentication,
    hastSiteId,
    isSkillerOrNotLogin,
    hasMenuPermission,
    handleMsgByNew,
    getClerkDetail,
    checkToken,
    webLogout,
    msgList,
    theme,
    setLoginOutListener,
    openId,
    tabBarRedDot
  };
});
