import { createRouter } from 'uni-mini-router';
import { userRouterNext } from '@/uni_modules/base-plugin/router/routerInterception';

const router = createRouter({
    routes: [...ROUTES], // 路由表信息
});

userRouterNext(router); //使用路由拦截

export default router;
