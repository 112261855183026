import createHttp from '@/uni_modules/base-plugin/utils/request';

export const personPullOfflineMessage = (minId:number) => {
    return createHttp.get('/privateMessage/pullOfflineMessage', {minId:minId});
};

export const sendPrivateMessage = (data:any) => {
    return createHttp.post('/privateMessage/sendPrivateMessage', data);
};
export const readMessage = (recvUserId:any) => {
    return createHttp.get('/privateMessage/readMessage', {recvUserId:recvUserId});
};
export const maxReadedId = (recvUserId:any) => {
    return createHttp.get('/privateMessage/maxReadedId', {recvUserId:recvUserId});
};

export const updatePrivateMessage = (newMessage:string,oldMessage:string) => {
    return createHttp.post('/privateMessage/updatePrivateMessage', {newMessage:newMessage,oldMessage:oldMessage});
};

export const updateCompanyPrivateMessage = (newMessage:string,oldMessage:string) => {
    return createHttp.post('/privateMessage/updateCompanyPrivateMessage', {newMessage:newMessage,oldMessage:oldMessage});
};
