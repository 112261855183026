// 路由拦截
import type { Router } from 'uni-mini-router';
import { useAuthStore } from '@/uni_modules/base-plugin/state/modules/auth';
import { loginPageName } from '@/uni_modules/base-plugin/utils/dict';
import { whiteList } from '@/router/whiteList';
import { Route } from 'uni-mini-router/lib/interfaces';
import router from '@/router';

export function userRouterNext(router: Router) {
    console.log('userRouterNext：' + router);
    router.beforeEach((to, from, next) => {
        console.log('beforeEach', to, from);
        const authStore = useAuthStore();
        // 判断是否需要登录
        if (authStore.isLogin) {
            console.log('是否登录：' + authStore.isLogin);
            // 如果登录了就放行
            next();
        } else {
            if ((!authStore.isLogin && to && to.name !== loginPageName) || from.query.byUser) {
                // 判断一下是否白名单里面的东西 如果是的话就代表直接跳转无需判断是否有token
                console.log('开始验证路由：' + to.name);
                if (whiteList.includes(to.name as string)) {
                    console.log('可以跳过：' + to.name);
                    next(true);
                } else {
                    console.log('需要登录，不可以跳过：' + to.name);
                    // 判断不是白名单 还没有token值 直接进去登录页面页面
                    next({
                        name: loginPageName,
                        params: { ...to.query },
                    });
                }
            } else if (!authStore.isLogin && to && to.name === loginPageName) {
                // 执行默认跳转逻辑
                next();
            } else if (authStore.isLogin && to && to.name === loginPageName) {
                console.log('重定向到home');
                router.replaceAll({ name: 'home' });
            }
        }
    });

    router.afterEach((to: Route, from: Route) => {
        console.log('afterEach', to, from);
        const authStore = useAuthStore();
        const pages = getCurrentPages();
        // if (pages.length) {
        //     uni.request({
        //         data: {
        //             openId: authStore.openId,
        //             skillerId: authStore.skiller.id,
        //             pageUrl: pages[pages.length - 1].route
        //         },
        //         method: "POST",
        //         url: "/useractive/add",
        //         success: (res) => {
        //         }
        //     });
        // }
    });
}

export const routerPush = (name: string, params: any) => {
    router.push({
        name: name,
        params: params,
    });
};
