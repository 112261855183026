const isNormal = (type: number): boolean => {
    return type >= 0 && type < 10;
}

const isStatus = (type: number): boolean => {
    return type >= 10 && type < 20;
}

const isTip = (type: number): boolean => {
    return type >= 20 && type < 30;
}

const isAction = (type: number): boolean => {
    return type >= 40 && type < 50;
}

const isRtcPrivate = (type: number): boolean => {
    return type >= 100 && type < 300;
}

const isRtcGroup = (type: number): boolean => {
    return type >= 200 && type < 400;
}

export {
    isNormal,
    isStatus,
    isTip,
    isAction,
    isRtcPrivate,
    isRtcGroup
}
