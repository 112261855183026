<script setup lang="ts">
import { onLaunch, onShow, onHide } from '@dcloudio/uni-app';
import { isHelpDevice } from "@/uni_modules/base-plugin/utils/dict";

onLaunch(() => {
    // handleMsgByNew();
    console.log('App Launch');
    startChat();
});

onShow(() => {
    console.log('App Show');
});

onHide(() => {
    console.log('App Hide');
});

const startChat = () => {
    //获取当前网络状态
    uni.getNetworkType({
        success: function (res) {
            init()
        },
    });

    //监听网络变化
    uni.onNetworkStatusChange(function (res) {
        console.log('网络类型:' + res.networkType + ',网络连接:' + res.isConnected);
        if (!res.isConnected) {
            // uni.showToast({
            //     title: '您的网络已断开连接！',
            //     icon: 'none',
            // });
            stop();
            return;
        } else {
            init()
            // useChatStore().start();
        }
    });

    // uni.onSocketOpen((res) => {
    //     console.log('WebSocket连接已打开！');
    // });
    //
    // uni.onSocketError((res) => {
    //     console.log('WebSocket连接打开失败，请检查！');
    // });
    //
    // uni.onSocketClose((res) => {
    //     console.log('WebSocket 已关闭！');
    // });
    //
    // uni.onSocketMessage((res) => {
    //     console.log('收到服务器内容：' + res.data);
    //     useChatStore().receiveMsg(JSON.parse(res.data));
    // });

























};
const init=()=>{
  if (!isHelpDevice) {
    // useChatStore().init();
  }
}
</script>
<style>
@import url('./style/index.css');
</style>
