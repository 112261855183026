import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import GetLocationSuccess = UniNamespace.GetLocationSuccess;
import { queryBySn } from '@/uni_modules/base-plugin/api/helpDevice/helpDevice';
import { R } from '@/uni_modules/base-plugin/types/global';
import { getCity } from '@/uni_modules/base-plugin/api/address/address';

import qqmapsdk from '@/uni_modules/base-plugin/utils/qqmap-wx-jssdk';
import { isHelpDevice } from "@/uni_modules/base-plugin/utils/dict";

export interface Position {
    country: string; // 国家
    province: string; // 省
    city: string; // 市
    district: string; // 区
    longitude: number; //经度
    latitude: number; //纬度
}

export const usePositionStore = defineStore('positionStore', () => {

    const defaultPosition = { district: '九龙坡区', latitude: 29.496239, longitude: 106.482057 } as Position;

    const positionStore = ref<Position>(uni.getStorageSync('position') || {} as Position);

    const position = computed(() => positionStore.value);

    uni.onNetworkStatusChange( (res) => {
        console.log('网络类型:' + res.networkType + ',网络连接:' + res.isConnected);
        if (!res.isConnected) {
        } else {
            getPosition();
        }
    });

    const getPosition = () => {
        // 判断是否是自助就业设备
        if (isHelpDevice) {
            const workModule = uni.requireNativePlugin('WorkModule');
            workModule.getDeviceSN({}, (data: any) => {
                queryBySn({ sn: data.sn }).then((data: R<any>) => {
                    positionStore.value = data.data;
                });
            });
        } else {
            getCity().then((data: R<Position>) => {
                if (!positionStore.value.latitude) {
                    positionStore.value = data.data || defaultPosition
                    uni.setStorageSync('position', positionStore.value);
                }
                getLocation();
            });
        }
    };

    const getLocation = () => {
        uni.getLocation({
            type: 'gcj02',
            success: (result: GetLocationSuccess) => {
                Object.assign(positionStore.value, result);
                uni.setStorageSync('position', positionStore.value);
                const isDev = import.meta.env.MODE === 'development';
                if (isDev) {
                    return
                }
                qqmapsdk.reverseGeocoder({ //腾讯的地图的接口 经纬度查位置 //并没有很精确
                    location: {
                        latitude: result.latitude,
                        longitude: result.longitude
                    },
                    success: (addressRes) => {
                        Object.assign(positionStore.value, addressRes.result.address_component);
                        uni.setStorageSync('position', positionStore.value);
                    },
                    fail: (error) => {
                    },
                })


            },
            fail: (error) => {},
            complete: (result: any) => {},
            isHighAccuracy: true,
        });
    };

    return {
        position,
        getPosition,
    };
});
