import {useAuthStore} from "@/uni_modules/base-plugin/state/modules/auth";

let wsurl = "";
let accessToken = "";
let messageCallBack: ((cmd: number, data: any) => void) | null = null;
let closeCallBack: ((res: any) => void) | null = null;
let connectCallBack: (() => void) | null = null;
let isConnect = false; // 连接标识 避免重复连接
let rec: ReturnType<typeof setTimeout> | null = null;
let isInit = false;
let lastConnectTime = new Date(); // 最后一次连接时间

const init = (): void => {
    const authStore = useAuthStore();
    accessToken=authStore.state.token
    // 防止重复初始化
    if (isInit) {
        return;
    }
    isInit = true;
    uni.onSocketOpen((res: any) => {
        console.log("WebSocket连接已打开");
        isConnect = true;
        // 发送登录命令
        const loginInfo = {
            cmd: 0,
            data: {
                accessToken: accessToken
            }
        };
        uni.sendSocketMessage({
            data: JSON.stringify(loginInfo)
        });
        console.log("WebSocket连接已打开",loginInfo);
    });

    uni.onSocketMessage((res: any) => {
        const sendInfo = JSON.parse(res.data);
        if (sendInfo.cmd === 0) {
            heartCheck.start();
            connectCallBack && connectCallBack();
            console.log('WebSocket登录成功');
        } else if (sendInfo.cmd === 1) {
            // 重新开启心跳定时
            heartCheck.reset();
        } else {
            // 其他消息转发出去
            console.log("接收到消息", sendInfo);
            messageCallBack && messageCallBack(sendInfo.cmd, sendInfo.data);
        }
    });

    uni.onSocketClose((res: any) => {
        console.log('WebSocket连接关闭');
        isConnect = false;
        closeCallBack && closeCallBack(res);
    });

    uni.onSocketError((e: any) => {
        console.log(e);
        isConnect = false;
        // APP 应用切出超过一定时间(约1分钟)会触发报错，此处回调给应用进行重连
        closeCallBack && closeCallBack({ code: 1006 });
    });
};

const connect = (url: string, token: string): void => {
    wsurl = url;
    accessToken = token;
    if (isConnect) {
        return;
    }
    lastConnectTime = new Date();
    uni.connectSocket({
        url: wsurl,
        success: (res: any) => {
            console.log("websocket连接成功");
        },
        fail: (e: any) => {
            console.log(e);
            console.log("websocket连接失败，10s后重连");
            setTimeout(() => {
                connect(url, token);
            }, 10000);
        }
    });
};

// 定义重连函数
const reconnect = (wsurl: string, accessToken: string): void => {
    console.log("尝试重新连接");
    if (isConnect) {
        // 如果已经连上就不在重连了
        return;
    }
    // 延迟10秒重连 避免过多次过频繁请求重连
    const timeDiff = new Date().getTime() - lastConnectTime.getTime();
    const delay = timeDiff < 10000 ? 10000 - timeDiff : 0;
    rec && clearTimeout(rec);
    rec = setTimeout(() => {
        connect(wsurl, accessToken);
    }, delay);
};

// 设置关闭连接
const close = (code: number): void => {
    if (!isConnect) {
        return;
    }
    uni.closeSocket({
        code: code,
        complete: (res: any) => {
            console.log("关闭websocket连接");
            isConnect = false;
        },
        fail: (e: any) => {
            console.log("关闭websocket连接失败", e);
        }
    });
};

// 心跳设置
const heartCheck = {
    timeout: 10000, // 每段时间发送一次心跳包 这里设置为30s
    timeoutObj: null as ReturnType<typeof setTimeout> | null,
    start(): void {
        if (isConnect) {
            console.log('发送WebSocket心跳');
            const heartBeat = {
                cmd: 1,
                data: {}
            };
            uni.sendSocketMessage({
                data: JSON.stringify(heartBeat),
                fail(res: any) {
                    console.log(res);
                }
            });
        }
    },
    reset(): void {
        if (this.timeoutObj) {
            clearTimeout(this.timeoutObj);
        }
        this.timeoutObj = setTimeout(() => {
            heartCheck.start();
        }, this.timeout);
    }
};

// 实际调用的方法
const sendMessage = (agentData: any): void => {
    uni.sendSocketMessage({
        data: agentData
    });
};

const onConnect = (callback: () => void): void => {
    connectCallBack = callback;
};

const onMessage = (callback: (cmd: number, data: any) => void): void => {
    messageCallBack = callback;
};

const onClose = (callback: (res: any) => void): void => {
    closeCallBack = callback;
};

// 将方法暴露出去
export {
    init,
    connect,
    reconnect,
    close,
    sendMessage,
    onConnect,
    onMessage,
    onClose
};
