export default {
  platform:'qfzp',
  defaultRouter: {
    globalStyle: {
      backgroundColor: "#FAFCFF"
    },
    color: "#232323",
    selectedColor: "#232323",
    list: [
      {
        pagePath: "pages/home/home",
        text: "首页",
        iconPath: "static/image/tabbar/SYTB@2x.png",
        selectedIconPath: "static/image/tabbar/SYTB-S@2x.png"
      },
      {
        pagePath: "pages/wode/index",
        text: "零工",
        iconPath: "static/image/tabbar/ZRC@2x.png",
        selectedIconPath: "static/image/tabbar/LINGG1@2x.png"
      },
      {
        pagePath: "uni_modules/qf-plugin/pages/news/news",
        text: "资讯",
        iconPath: "static/image/tabbar/ZGZXTB@2x.png",
        selectedIconPath: "static/image/tabbar/REPIN1@2x.png",
        style: {
          backgroundColor: "#FAFCFF"
        }
      },
      {
        pagePath: "uni_modules/qf-plugin/pages/msg/msg",
        text: "消息",
        iconPath: "static/image/tabbar/SYXX@2x.png",
        selectedIconPath: "static/image/tabbar/SYXX1@2x.png"
      },
      {
        pagePath: "pages/user/user",
        text: "我的",
        iconPath: "static/image/tabbar/WD@2x.png",
        selectedIconPath: "static/image/tabbar/WD1@2x.png"
      }
    ]
  }
} as any;
