import createHttp from '@/uni_modules/base-plugin/utils/request';

export const newToken = () => {
    return createHttp.get('/newToken', {});
};

export const refreshCheck = (data: any) => {
    return createHttp.get('/refreshCheck', data);
};

export const postLoginCache = (data: any) => {
    return createHttp.post('/postLoginCache', data);
};

export const switchType = (data: any) => {
    return createHttp.post('/login/switchType', data);
};

export const getNewToken = (data: any) => {
    return createHttp.post('/login/getNewToken', data);
};
