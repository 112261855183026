import request from '@/uni_modules/base-plugin/utils/request'; //导入http下的httpload.js

export function getLoginKey(data: Record<string, any>) {
    // get请求 可以拼接url或者传入数据对象 二选一
    return request.get('/getLoginKey', { code: data });
}

export function wxlogin(data: any) {
    // get请求 可以拼接url或者传入数据对象 二选一
    return request.post('/wxlogin', data);
}

export const appCodeLogin = (data: any) => {
    return request.post('/appCodeLogin', data);
};

export const refreshToken = (data: any) => {
    return request.post('/refreshToken', data);
};

export const checkUserToken = (data: any) => {
    return request.post('/checkUserToken', data);
};
