// 路由拦截
export const whiteList = [
    'home',
    'sitesHome',
    'siteRankingList',
    'hotWork',
    'user',
    'msg',
    'wode',
    'login',
    'userArticle',
    'codeLogin',
    'userinfo',
    'workDetail',
    'agreement',
    'trainList',
    'help',
    'law',
    'trainDetail',
    'skillerList',
    'skillerDetail',
    'recruitmentEvent',
    'webview',
    'company',
    'companyDetail',
    'eventDetail',
    'yzsy',
    'news',
    'informationDetail',
    'studentRecruitment',
    'schoolEnterprises',
    'serviceStations',
    'lawList',
    'legalAid',
    'legalAidDetails',
    'lawInformation',
    'protectionReport',
    'editPage',
    'contact',
    'searchWork',
    'editClerk'
];
