export function showToast(msg, callback = function () {
}) {
    uni.showToast({
        title: msg,
        icon: 'none',
        duration: 2000,
        mask: true,
        success() {
            setTimeout(function () {
                callback();
            }, 2000);
        },
    });
}

export function getCustomNav() {
    let result = {} as any
    uni.getSystemInfo({
        success(e) {
            let myStatusBar = 0;
            let myCustomBar = 0;
            let myNvaMartom = 0;
            let myNavHeight = 0;
            let myNavWidth = 0;
            let custom = uni.getMenuButtonBoundingClientRect();
            myStatusBar = e.statusBarHeight;
            myNavHeight = custom.height;
            myNavWidth = custom.width;
            myNvaMartom = custom.top - e.statusBarHeight;
            myCustomBar = myNvaMartom * 2 + custom.height - 2;
            result.statusBar = (myStatusBar * 750) / wx.getSystemInfoSync().windowWidth; //顶部状态栏高度
            result.customBar = (myCustomBar * 750) / wx.getSystemInfoSync().windowWidth + 12; //导航栏高度
            result.nvaMartom = (myNvaMartom * 750) / wx.getSystemInfoSync().windowWidth; //胶囊上边距
            result.navHeight = (myNavHeight * 750) / wx.getSystemInfoSync().windowWidth; //胶囊按钮高度
            result.navWidth = (myNavWidth * 750) / wx.getSystemInfoSync().windowWidth; //胶囊按钮宽度
            result.navMarRight = 750 - (custom.right * 750) / wx.getSystemInfoSync().windowWidth; //胶囊右边距
            result.pageMagTop = result.customBar + result.statusBar; //页面内容距离顶部的上边距，导航栏全部高度
        },
    });
    return result;
}

export function multipleChoiceChangeObj(targetName, value, vm, max = -1) {
    const data = vm[targetName];
    if (!data[value.id]) {
        if (max == -1 || Object.keys(data).length < max) {
            vm.$set(vm[targetName], value.id, value);
        } else {
            uni.showToast({
                title: `最多只能选择${max}个`,
                icon: 'none',
                mask: true,
            });
        }
    } else {
        vm.$delete(vm[targetName], value.id);
    }
}

export function getCurrentTime(fields = 's', time = null) {
    const date = time ? new Date(time) : new Date();
    let str = '';
    let y = date.getFullYear().toString();
    let m = (date.getMonth() + 1).toString();
    m = m.length == 1 ? '0' + m : m;
    let d = date.getDate().toString();
    d = d.length == 1 ? '0' + d : d;
    let h = date.getHours().toString();
    h = h.length == 1 ? '0' + h : h;
    let i = date.getMinutes().toString();
    i = i.length == 1 ? '0' + i : i;
    let s = date.getSeconds().toString();
    s = s.length == 1 ? '0' + s : s;
    if (fields == 'y') {
        str = y;
    }
    if (fields == 'm') {
        str = y + '-' + m;
    }
    if (fields == 'd') {
        str = y + '-' + m + '-' + d;
    }
    if (fields == 'h') {
        str = y + '-' + m + '-' + d + ' ' + h;
    }
    if (fields == 'i') {
        str = y + '-' + m + '-' + d + ' ' + h + ':' + i;
    }
    if (fields == 's') {
        str = y + '-' + m + '-' + d + ' ' + h + ':' + i + ':' + s;
    }
    return str;
}

export function get24HoursTime(time) {
    if (time.indexOf('上午') != -1) {
        time = time.replace('上午', '');
        let arr = time.split(':');
        arr[0] = arr[0] == '12' ? '00' : arr[0];
        arr[0] = arr[0].length == 2 ? arr[0] : '0' + arr[0];
        time = arr.join(':');
    }
    if (time.indexOf('下午') != -1) {
        time = time.replace('下午', '');
        let arr = time.split(':');
        arr[0] = arr[0] == '12' ? arr[0] : arr[0] * 1 + 12;
        arr[0] = arr[0] == '24' ? '00' : arr[0].toString();
        arr[0] = arr[0].length == 2 ? arr[0] : '0' + arr[0];
        time = arr.join(':');
    }
    return time;
}

export function getAuthorize(scopeName, authorizeName) {
    return new Promise((resolve, reject) => {
        uni.authorize({
            scope: scopeName,
            success: () => {
                resolve({});
            },
            fail: () => {
                uni.showModal({
                    title: '提示',
                    content: '您拒绝了' + authorizeName + '授权，请前往设置页开启',
                    confirmText: '去开启',
                    success(e) {
                        if (e.confirm) {
                            uni.openSetting();
                        }
                    },
                });
                reject();
            },
        });
    });
}

export function getLocation() {
    return new Promise((resolve, reject) => {
        uni.getLocation({
            isHighAccuracy: true,
            geocode: true,
            success(e) {
                resolve(e);
            },
            fail() {
                uni.showToast({
                    title: '获取当前位置失败',
                    icon: 'none',
                    mask: true,
                });
                reject();
            },
        });
    });
}

export function chooseLocation(latitude, longitude) {
    return new Promise((resolve, reject) => {
        uni.chooseLocation({
            latitude,
            longitude,
            success(e) {
                resolve({address: e.address, latitude: e.latitude, longitude: e.longitude, name: e.name});
            },
            fail() {
                uni.showToast({
                    title: '位置选择失败',
                    icon: 'none',
                    mask: true,
                });
                reject();
            },
        });
    });
}

export function isMore(page, limit, total) {
    return page * limit < total;
}

export function keyValueToArray(data) {
    let res = [];
    Object.keys(data).forEach((item) => {
        res.push({id: item, name: data[item]});
    });
    return res;
}

export function getCurrentPage() {
    let pages = getCurrentPages(); //获取加载的页面
    return pages[pages.length - 1].route; //当前页面的page.json里面的name
}

const formatNumber = (n) => {
    n = n.toString();
    return n[1] ? n : '0' + n;
};

export const formatTime = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const second = date.getSeconds();

    return [year, month, day].map(formatNumber).join('/') + ' ' + [hour, minute, second].map(formatNumber).join(':');
};

export const helpDevice = () => {
    const systemInfoSync = uni.getSystemInfoSync();
    return systemInfoSync.deviceBrand === 'rockchip' || systemInfoSync.deviceModel === 'rk3566_r';
};

export const getLoginPageName = () => {
    const systemInfoSync = uni.getSystemInfoSync();
    if (systemInfoSync.uniPlatform == 'web') {
        return 'codeLogin';
    }
    const name = helpDevice() ? 'codeLogin' : 'login';
    console.log('开始获取getLoginPageName' + name)
    return name;
};

export const clearHtmlTag = (str: string) => {
    // 首先移除所有 HTML 标签
    let result = str.replace(/<[^>]+>/g, '');

    // 然后移除“岗位id”相关的内容
    result = result.replace(/岗位id：\d+；/g, '');

    return result;
};

export const randomNum = (min: number, max: number) => {
    if (min > max) {
        throw new Error("最小值不能大于最大值");
    }
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const sumLastTwoDigits = (str: string, i: number) => {
    // 将字符串分割成数组
    const numbers = str.slice(-2).split('');

    // 将数组中的每个元素转换为数字，并相加
    const sum = numbers.reduce((acc, num) => acc + parseInt(num, 10), i);

    // 将结果转换为字符串，并取最后两位
    const lastTwoDigits = sum.toString().slice(-2);

    // 返回最后两位数字，如果不足两位前面补零
    return parseInt(lastTwoDigits, 10) + 6;
}
