import createHttp from '@/uni_modules/base-plugin/utils/request';

export const getCompanyById = (params: any) => {
    return createHttp.get('/company/detail', params);
};

export const getDetail = (id: string) => {
    return createHttp.post('/company/getDetail', { id });
};
export const getCompanyDetailQRCode = (id: string) => {
    return createHttp.get('/company/getCompanyDetailQRCode', { id: id });
};

export const updateCompany = (form:any) => {
    return createHttp.post('/company/update', form);
}