import { strDec } from "@/uni_modules/base-plugin/utils/DES";
import { deepClone } from "wot-design-uni/components/common/util";
import { DictDataOption } from "@/uni_modules/base-plugin/types/global";
import { getList } from "@/uni_modules/base-plugin/api/company/compType";
import useDictStore from "@/uni_modules/base-plugin/state/modules/dict";
import { getLoginPageName, helpDevice } from "@/uni_modules/base-plugin/util/util";
import menu from "@/router/menu";

export const loginPageName = getLoginPageName();

export const isHelpDevice = helpDevice()

/**
 * 是否是前锋直聘
 */
export const isQfzp = menu.platform === 'qfzp';

/**
 * 获取字典数据
 */
export const useDict = (...args: string[]): { [key: string]: DictDataOption[] } => {
  const res = ref<{
    [key: string]: DictDataOption[];
  }>({});
  return (() => {
    args.forEach(async (dictType) => {
      res.value[dictType] = [];
      const dicts = useDictStore().getDict(dictType);
      if (dicts) {
        res.value[dictType] = dicts;
      } else {
        if (dictType === "companyType") {
          await getList().then((resp: any) => {
            res.value[dictType] = resp.data.map((p): DictDataOption => ({ label: p.name, value: p.id }));
            useDictStore().setDict(dictType, res.value[dictType]);
          });
        }
      }
    });
    return res.value;
  })();
};

export const USER_MORE_WORK_SKILL = 1;

export const USER_MORE_ODD_SKILL = 2;

export const RELEASE_WORK_SKILL = 3;

export const RELEASE_ODD_SKILL = 4;

export const genderOptions = [
  { label: "男", value: 1 },
  { label: "女", value: 2 }
];

export const sexOptions = [
  { label: "男", value: 1 },
  { label: "女", value: 2 }
];

export const clerkOptions = [
  { label: "站长", value: "5" },
  { label: "服务员", value: "3" },
  { label: "宣传员", value: "4" }
];

export const partJobTypeOptions = [
  { label: "全职", value: "全职" },
  { label: "零工兼职", value: "零工兼职" }
];

export const partTimeTypeOptions = [
  { label: "线上就业", value: "0" },
  { label: "线下就业", value: "1" }
];

export const workJy2Options = [
  { label: "不限经验", value: "不限经验" },
  { label: "1年以下", value: "1年以下" },
  { label: "1~3年", value: "1~3年" },
  { label: "3~5年", value: "~5年" },
  { label: "5~10年", value: "5~10年" },
  { label: "10年以上", value: "10年以上" }
];

export const workSalaryTypeOptions = [
  { label: "天", value: "天" },
  { label: "小时", value: "小时" },
  { label: "周结", value: "周结" },
  { label: "月结", value: "月结" }
];

export const skillerStateOptions = [
  { label: "离职-随时到岗", value: 1 },
  { label: "在职-月内到岗", value: 2 }
  // { label: '在职-考虑机会', value: 3 },
  // { label: '在职-暂不考虑', value: 4 },
];

export const salaryOptions = [
  { value: "2000以上", label: "2000以上" },
  { value: "3000以上", label: "3000以上" },
  { value: "4000以上", label: "4000以上" },
  { value: "5000以上", label: "5000以上" },
  { value: "6000以上", label: "6000以上" },
  { value: "7000以上", label: "7000以上" },
  { value: "8000以上", label: "8000以上" }
];

export const educationOptions = [
  // { label: '小学', value: 1 },
  // 谢主任考虑到居民学历可能出现小学文盲的情况，所以设置最低学历为初中及以下
  { label: "初中及以下", value: 1 },
  { label: "高中（职高/中专）", value: 2 },
  { label: "大专", value: 3 },
  { label: "本科及以上", value: 4 }
];

export const communityOptions = [{ label: "睦邻路社区", value: 1 }];

export const educationOption = [
  { label: "小学", value: 1 },
  { label: "初中", value: 2 },
  { label: "高中（职高/中专）", value: 3 },
  { label: "大专", value: 4 },
  { label: "本科", value: 5 },
  { label: "培训机构", value: 6 }
];

export const jobStateOptions = [
  { label: "工作中", value: "1", text: "工作中" },
  { label: "待业中", value: "2", text: "待业中" },
  { label: "灵活就业人员", value: "3", text: "灵活就业人员" }
];

export const workHopeOptions = [
  { label: "家乡", value: 1 },
  { label: "异地", value: 2 },
  { label: "都可以", value: 3 }
];

export const recruitmentStateOptions = [
  { label: "活动中", value: '1' },
  { label: "待举办", value: '0' },
  { label: "已结束", value: '2' }
];

export const companyScaleOptions = [
  { label: "20人以下", value: "1" },
  { label: "20-99人", value: "2" },
  { label: "100-299人", value: "3" },
  { label: "300-499人", value: "4" },
  { label: "500-999人", value: "5" },
  { label: "1000-9999人", value: "6" },
  { label: "10000人以上", value: "7" }
];

export const projectGovermentStateOptions = [
  { label: "待审核", value: "0" },
  { label: "通过", value: "1" },
  { label: "已下架", value: "-1" },
  { label: "未提交", value: "-2" }
];

export const informationStateOptions = [
  { label: "已下架", value: "-1" },
  { label: "未发布", value: "0" },
  { label: "已发布", value: "1" }
];

export const lawBrowseStateOptions = [
  { label: "待审核", value: "0" },
  { label: "已发布", value: "1" },
  { label: "已下架", value: "-1" },
  { label: "未提交", value: "-2" }
];

export const workJyOptions = [
  { label: "不限经验", value: "1" },
  { label: "1年以下", value: "2" },
  { label: "1~3年", value: "3" },
  { label: "3~5年", value: "4" },
  { label: "5~10年", value: "5" },
  { label: "10年以上", value: "6" }
];

export const skillerOpenOptions = [
  { label: "求职已关闭", value: "0" },
  { label: "求职已开启", value: "1" }
];

export const workStateOptions = [
  { label: "未发布", value: "0" },
  { label: "发布中", value: "1" },
  { label: "删除", value: "-1" },
  { label: "招聘完成", value: "2" },
  { label: "审核中", value: "3" },
  { label: "审核失败:请联系站长", value: "4" },
  { label: "已撤回申请", value: "5" }
];

export const oddJodOptions = [
  { label: "未完成/工作中", value: "0" },
  { label: "已完成", value: "1" },
  { label: "被选中", value: "2" },
  { label: "待通过", value: "3" }
];

export const shareWorkType = [
  { label: "已分享到站点", value: "1" },
  { label: "已分享到群", value: "2" }
];

export const companyNatureOptions = [
  { label: "用工单位", value: "1" },
  { label: "劳务派遣单位", value: "2" },
  { label: "个体工商户", value: "3" }
];

export const keyPersonnelOptions = [
  { label: "是", value: "1" },
  { label: "否", value: "0" }
];

export const obtainOptions = [
  { label: "一年就业经验", value: "1" },
  { label: "二年就业经验", value: "2" },
  { label: "三年就业经验", value: "3" },
  { label: "四年就业经验", value: "4" },
  { label: "五年就业经验", value: "5" }
];

export const personnelOptions = [
  { label: "就业困难人员", value: "1" },
  { label: "高校毕业生", value: "2" },
  { label: "在校大学生", value: "3" },
  { label: "脱贫人口", value: "4" },
  { label: "4050人员", value: "5" },
  { label: "退役军人", value: "6" },
  { label: "残疾人", value: "7" },
  { label: "低保户家庭失业人员", value: "8" },
  { label: "其他人员", value: "9" },
  { label: "刑满释放", value: "10" },
  { label: "戒毒康复人员", value: "11" },
  { label: "雨露计划", value: "12" },
  { label: "西部志愿者（服务期满）", value: "13" },
  { label: "特困救助供养", value: "14" },
  { label: "防返贫监测对象", value: "15" },
  { label: "化解过剩产能企业员工", value: "16" },
  { label: "农村劳动力", value: "17" },
  { label: "文化创意人员", value: "18" },
  { label: "信息技术人员", value: "19" },
  { label: "未升学初高中毕业生", value: "20" },
  { label: "网络商户", value: "21" },
  { label: "国企下岗失业人员", value: "22" },
  { label: "国企关闭破产安置人员", value: "23" },
  { label: "国有企业所办集体企业下岗职工", value: "24" }
];


export const cypYcslRegisteredOptions = [
  { label: "非农业户口", value: "1" },
  { label: "本市城镇", value: "2" },
  { label: "非本市城镇", value: "3" },
  { label: "农业户口", value: "4" },
  { label: "本市农村", value: "5" },
  { label: "非本市农村", value: "6" },
  { label: "台港澳人员", value: "7" },
  { label: "香港特别行政区居民", value: "8" },
  { label: "台湾地区居民", value: "9" },
  { label: "华侨", value: "10" },
  { label: "外籍人员", value: "11" },
  { label: "未取得永久居留权的外国人", value: "12" },
  { label: "取得永久居留权的外国人", value: "13" },
  { label: "居民户口", value: "14" },
  { label: "本地居民户口", value: "15" },
  { label: "外地地居民户口", value: "16" },
  { label: "集体户口", value: "17" },
  { label: "其他", value: "18" }
];

export const policyOptions = [
  { label: "失业登记", value: "1" },
  { label: "失业保险", value: "2" },
  { label: "高校毕业生", value: "3" },
  { label: "创业担保", value: "4" },
  { label: "城镇企业职工", value: "5" }
];

export const onlineOptions = [
  { label: "职业指导", value: "4" },
  { label: "职业培训", value: "5" },
  { label: "创业服务", value: "6" }
];
export const jobSeekingStatusOptions = [
  { label: "暂不就业", value: 1 },
  { label: "已就业", value: 2 },
  { label: "仍需帮扶", value: 3 }
];


export const streetIdOptions = [
  { label: "社区1", value: '1' },
  { label: "社区2", value: '2' },
  { label: "社区3", value: '3' }
];
export const wayListOptions = [
  { label: "仅退款", value: '10' },
  { label: "退款退货", value: '20' }
];

export const villageCheckOptions = [{ label: "重庆市渝北区仙桃街道", value: "0" }];

export const getDictLabel = (dictName: any, value: any, defaultValue: any = "/"): any => {
  if (dictName == "education") {
    return educationOptions.find((item) => item.value == value)?.label || defaultValue;
  } else if (dictName == "community") {
    return communityOptions.find((item) => item.value == value)?.label || defaultValue;
  }  else if (dictName == "clerk") {
    return clerkOptions.find((item) => item.value == value)?.label || defaultValue;
  } else if (dictName == "recruitment") {
    return recruitmentStateOptions.find((item) => item.value == value)?.label || defaultValue;
  } else if (dictName == "personnel") {
    return personnelOptions.find((item) => item.value == value)?.label || defaultValue;
  } else if (dictName == "online") {
    return onlineOptions.find((item) => item.value == value)?.label || defaultValue;
  } else if (dictName == "obtain") {
    return obtainOptions.find((item) => item.value == value)?.label || defaultValue;
  } else if (dictName == "policy") {
    return policyOptions.find((item) => item.value == value)?.label || defaultValue;
  } else if (dictName == "sex") {
    return sexOptions.find((item) => item.value == value)?.label || defaultValue;
  } else if (dictName == "jobState") {
    return jobStateOptions.find((item) => item.value == value)?.label || defaultValue;
  } else if (dictName == "phone" || dictName == "idCard") {
    return strDec(value, "tgqj", "51tgqj") || value;
  } else if (dictName == "state") {
    return informationStateOptions.find((item: any) => item.value == value)?.label || defaultValue;
  } else if (dictName == "information") {
    return informationStateOptions.find((item: any) => item.value == value)?.label || defaultValue;
  } else if (dictName == "lawBrowse") {
    return lawBrowseStateOptions.find((item: any) => item.value == value)?.label || defaultValue;
  } else if (dictName == "law") {
    return lawBrowseStateOptions.find((item: any) => item.value == value)?.label || defaultValue;
  } else if (dictName == "workingYear") {
    return workJyOptions.find((item: any) => item.value == value)?.label || defaultValue;
  } else if (dictName == "skillerOpen") {
    return skillerOpenOptions.find((item: any) => item.value == value)?.label || defaultValue;
  } else if (dictName == "companyScale") {
    return companyScaleOptions.find((item: any) => item.value == value)?.label || defaultValue;
  } else if (dictName == "workState") {
    return workStateOptions.find((item: any) => item.value == value)?.label || defaultValue;
  } else if (dictName == "oddJod") {
    return oddJodOptions.find((item: any) => item.value == value)?.label || defaultValue;
  } else if (dictName == "shareWorkType") {
    return shareWorkType.find((item: any) => item.value == value)?.label || defaultValue;
  } else if (dictName == "skillerState") {
    return skillerStateOptions.find((item: any) => item.value == value)?.label || defaultValue;
  } else if (dictName == "workJy") {
    return workJyOptions.find((item: any) => item.value == value)?.label || defaultValue;
  } else if (dictName == "partTimeType") {
    return partTimeTypeOptions.find((item: any) => item.value == value)?.label || defaultValue;
  } else if (dictName == "villageCheck") {
    return villageCheckOptions.find((item: any) => item.value == value)?.label || defaultValue;
  } else if (dictName == "companyNature") {
    return companyNatureOptions.find((item: any) => item.value == value)?.label || defaultValue;
  } else if (dictName == "keyPersonnel") {
    return keyPersonnelOptions.find((item: any) => item.value == value)?.label || defaultValue;
  } else if (dictName == "jobSeekingStatus") {
    return jobSeekingStatusOptions.find((item: any) => item.value == value)?.label || defaultValue;
  } else if (dictName == "cypYcslRegistered") {
    return cypYcslRegisteredOptions.find((item: any) => item.value == value)?.label || defaultValue;
  } else if (dictName == "way") {
    return wayListOptions.find((item: any) => item.value == value)?.label || defaultValue;
  } else if (dictName == "companyType") {
    if (!value) {
      return undefined;
    }
    const dictType = "companyType";
    if (dictType) {
      var dictDataOption = useDict(dictType)[dictType];
      const val = dictDataOption?.find((item: any) => item.value == value)?.label;
      return val;
    }
  } else if (dictName == "companyTypeList") {
    const dictType = "companyType";
    if (dictType) {
      var dictDataOption = useDict(dictType)[dictType];
      return dictDataOption;
    }
  } else if (dictName == "educations") {
    return educationOption.find((item: any) => item.value == value)?.label || defaultValue;
  } else if (dictName == "streetId") {
    return streetIdOptions.find((item: any) => item.value == value)?.label || defaultValue;
  } else {
    return value || defaultValue;
  }
};

export function calculateAge(birthDateString: string) {
  const today = new Date();
  const birthDate = new Date(birthDateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

/**
 * 复制对象，将复制后的对象与rules比对，移除在rules不存在的属性
 * @param formState
 * @param rules
 */
export const objClone = (formState: any, rules: any) => {
  const formData = deepClone(formState);
  const keys = Object.keys(formData);
  for (const ruleKey of Object.keys(rules)) {
    if (!formData[ruleKey]) {
      formData[ruleKey] = null;
    }
  }
  if (!keys.length) {
    for (const ruleKey of Object.keys(rules)) {
      formData[ruleKey] = null;
    }
  } else {
    for (const key of keys) {
      if (!rules[key]) {
        delete formData[key];
      } else if (!formData[key]) {
        formData[key] = null;
      }
    }
  }
  return formData;
};

/**
 * 复制对象，将复制后的对象与rules比对，移除在rules不存在的属性
 * @param userskill
 * @param rules
 */
export const objClone2 = (userskill: any, rules: any) => {
  const userskill2 = deepClone(userskill);
  const keys = Object.keys(userskill2);
  for (const ruleKey of Object.keys(rules)) {
    if (!userskill2[ruleKey]) {
      userskill2[ruleKey] = null;
    }
  }
  if (!keys.length) {
    for (const ruleKey of Object.keys(rules)) {
      userskill2[ruleKey] = null;
    }
  } else {
    for (const key of keys) {
      if (!rules[key]) {
        delete userskill2[key];
      } else if (!userskill2[key]) {
        userskill2[key] = null;
      }
    }
  }
  return userskill2;
};

/**
 * wd-datetime 格式化时间
 * @param type
 * @param value
 */
export const formatter = (type: string, value: string) => {
  switch (type) {
    case "year":
      return value + "年";
    case "month":
      return value + "月";
    case "date":
      return value + "日";
    case "hour":
      return value + "时";
    case "minute":
      return value + "分";
    default:
      return value;
  }
};
