import {findChatPersonnels, OnlineTerminals} from "../../base-plugin/api/chatPersonnel/chatPersonnel";
import {TERMINAL_TYPE} from "@/uni_modules/base-plugin/state/modules/enums";

export default {

	state: {
		friends: [],
		timer: null
	},
	mutations: {
		setFriends(state, friends) {
			friends?.forEach((f)=>{
				f.online = false;
				f.onlineWeb = false;
				f.onlineApp = false;
			})
			state.friends = friends;
		},
		updateFriend(state, friend) {
			console.log(state.friends)
			state.friends.forEach((f, index) => {
				if (!f.delete && f.id == friend.id) {
					// 拷贝属性
					let online = state.friends[index].online;
					Object.assign(state.friends[index], friend);
					state.friends[index].online = online;
				}
			})
		},
		removeFriend(state, id) {
			let friend = this.getters.findFriend(id);
			if(friend){
				friend.delete = true;
			}
		},
		addFriend(state, friend) {
			console.log("获取朋友信息："+friend)
			let f = this.getters.findFriend(friend.chattedWithUserId);
			if(f){
				Object.assign(f, friend);
				f.delete = false;
			}else{
				state.friends.push(friend);
			}
		},
		setOnlineStatus(state, onlineTerminals) {
			state.friends.forEach((f)=>{
				let userTerminal = onlineTerminals?.find((o)=> f.userId==o.chattedWithUserId);
				if(userTerminal){
					f.online = true;
					f.onlineWeb = userTerminal.terminals.indexOf(TERMINAL_TYPE.WEB)>=0
					f.onlineApp = userTerminal.terminals.indexOf(TERMINAL_TYPE.APP)>=0
				}else{
					f.online = false;
					f.onlineWeb = false;
					f.onlineApp = false;
				}
			})
		},
		refreshOnlineStatus(state) {
			if (state.friends?.length > 0) {
				let userIds = [];
				state.friends.forEach((f) => {
					userIds.push(f.chattedWithUserId)
				});
				OnlineTerminals(userIds.join(',')).then((res)=>{
					this.commit("setOnlineStatus", res.data);
				})
				// http({
				// 	url: '/user/terminal/online?userIds=' + userIds.join(','),
				// 	method: 'GET'
				// }).then((onlineTerminals) => {
				// 	this.commit("setOnlineStatus", onlineTerminals);
				//
				// })
			}
			// 30s后重新拉取
			clearTimeout(state.timer);
			state.timer = setTimeout(() => {
				this.commit("refreshOnlineStatus");
			}, 30000)
		},
		clear(state) {
			clearTimeout(state.timer);
			state.friends = [];
			state.timer = null;
		}
	},
	actions: {
		loadFriend(context) {
			return new Promise((resolve, reject) => {
				findChatPersonnels().then(res=>{
					context.commit("setFriends", res.data);
					context.commit("refreshOnlineStatus");
					resolve()
				}).catch((res)=>{
					reject(res);
				})
			});
		}
	},
	getters:{
		findFriend: (state) => (id) => {
			return state.friends.find((f)=>f.chattedWithUserId==id);
		}
	}
}
