import createHttp from '@/uni_modules/base-plugin/utils/request';

export const getCity = () => {
    return createHttp.get('/address/getCity',{});
};

export const addressCreate = (data:any) => {
    return createHttp.post('/member/address/create',data);
};

export const addressUpdate = (data:any) => {
    return createHttp.post('/member/address/update',data);
};

export const addressDelete = (data:any) => {
    return createHttp.get('/member/address/delete',data);
};

export const addressGet = (data:any) => {
    return createHttp.get('/member/address/get',data);
};


export const addressGetDefault = () => {
    return createHttp.get('/member/address/get-default');
};

export const addressList = () => {
    return createHttp.get('/member/address/list');
};
