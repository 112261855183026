import createHttp from '@/uni_modules/base-plugin/utils/request';

export const getListByBigType = () => {
    return createHttp.get('/workSkill/getListByBigType', null);
};

export const getOddListByBigType = () => {
    return createHttp.get('/workSkill/getOddListByBigType', null);
};

export const getOddListZgmType = () => {
    return createHttp.get('/workSkill/getOddListZgmType', null);
};

export const getDetail = (id: string, workId = '') => {
    return createHttp.get('/skiller/getDetail', {
        id: id,
        work_id: workId,
    });
};

export const getSkillerSelfDetail = () => {
    return createHttp.get('/skiller/selfDetail',{});
};

export const skillUpdate = (param: any) => {
    return createHttp.get('/skiller/update', param);
};

export const getList = (data: any) => {
    return createHttp.post('/skiller/getList', data);
};

export const getOddList = (data: any) => {
    return createHttp.post('/skiller/getOddList', data);
};
