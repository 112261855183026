import { createSSRApp } from 'vue';
import App from './App.vue';
import pinia from './uni_modules/base-plugin/state'; //pinia
import router from './router'; //路由
/* 富文本器 */
// #ifdef H5
import quill from 'quill';
window.Quill = quill;
// #endif

import uvUI from '@climblee/uv-ui'
import store from "@/uni_modules/qf-plugin/store";

export function createApp() {
    const app = createSSRApp(App);
    app.use(router);
    app.use(pinia);
    app.use(uvUI,{mpShare:true});
    app.use(store)
    return {
        app,
    };
}
