import type { R } from "@/uni_modules/base-plugin/types/global";
import { usePositionStore } from "@/uni_modules/base-plugin/state/modules/position";

export const baseUrl = import.meta.env.VITE_BASE_URL;

const httpInterceptor = {
  invoke(options: UniApp.RequestOptions) {
    if (!options.header) {
      options.header = {};
    }
    options.header["tenantId"] = import.meta.env.VITE_TENANT_ID;
    if (options.url === undefined || !(options.url.startsWith("http") || options.url.startsWith("https"))) {
      options.url = baseUrl + options.url;
      if (uni.getStorageSync("token")) {
        options.header = {
          ...options.header,
          Authorization: "Bearer " + uni.getStorageSync("token")
        };
      }
    }
    options.timeout = 88000;
  }
};

uni.addInterceptor("request", httpInterceptor);
uni.addInterceptor("uploadFile", httpInterceptor);

class HttpRequest<T> {
  request = (options: UniApp.RequestOptions) => {
    const data = options.data;
    if (data) {
      Object.keys(data).forEach((item) => {
        const dataItem = data[item];
        if (dataItem === null || dataItem === undefined) {
          delete data[item];
        }
      });
    }
    return new Promise<R<T>>((reslove, reject) => {
      uni.request({
        ...options,
        success: (res) => {
          if (res.statusCode >= 200 && res.statusCode < 300) {
            const data = res.data as R<T>;
            if (data.code === 401) {
              // router.push({
              //     name: loginPageName,
              // })
              // reject({});
              // uni.showToast({
              //     title: '用户未授权，请重新登录',
              //     icon: 'none',
              // });
            } else {
            }
            return reslove(res.data as R<T>);
          } else if (res.statusCode === 401) {
            uni.showToast({
              title: "用户未授权，请重新登录",
              icon: "none"
            });
          } else {
            // uni.showToast({
            //     title: (res.data as R<T>).msg,
            //     icon: 'none',
            // });
          }
          reject(res);
        },
        fail: (err) => {
          uni.showToast({
            title: "网络异常，请稍等重试",
            icon: "none"
          });
          reject(err);
        }
      });
    });
  };
  uploadPhoto = (options: UniApp.UploadFileOption) => {
    return new Promise<R<T>>((reslove, reject) => {
      uni.uploadFile({
        ...options,
        success: (res) => {
          if (res.statusCode >= 200 && res.statusCode < 300) {
            return reslove(JSON.parse(res.data) as R<T>);
          } else if (res.statusCode === 401) {
            uni.showToast({
              title: "用户未授权",
              icon: "none"
            });
          } else {
            // uni.showToast({
            //     title: (JSON.parse(res.data) as R<T>).msg || '服务器异常',
            //     icon: 'none',
            // });
          }
          reject(res);
        },
        fail: (err) => {
          uni.showToast({
            title: "网络异常，请稍等重试",
            icon: "none"
          });
          reject(err);
        }
      });
    });
  };
  get = (url: string, data?: any) => {
    const { position } = usePositionStore();
    const longitude = position?.longitude;
    const latitude = position?.latitude;
    const openId = uni.getStorageSync("openId")
    return this.request({
      url: url,
      data: {
        longitude,
        latitude,
        openId,
        ...data
      },
      method: "GET"
    });
  };
  post = (url: string, data: any) => {
    const { position } = usePositionStore();
    const longitude = position?.longitude;
    const latitude = position?.latitude;
    const openId = uni.getStorageSync("openId")
    return this.request({
      url: url,
      data: {
        longitude,
        latitude,
        openId,
        ...data
      },
      method: "POST"
    });
  };
}

export function createHttp<E>() {
  return httpRequest;
}

const httpRequest = new HttpRequest();

export default httpRequest;
