import createHttp from '@/uni_modules/base-plugin/utils/request';

export const getEconomyMan = () => {
    return createHttp.get('/economyMan/getEconomyMan', {});
};
export const EconomyManUpdate = (data:any) => {
    return createHttp.post('/economyMan/update', data);
};

export const findAllEconomyMan = (data:any) => {
    return createHttp.get('/economyMan/findAllEconomyMan', data);
};
export const getEconomyManId = (data:any) => {
    return createHttp.get('/economyMan/getEconomyManId', data);
};
export const home = () => {
    return createHttp.get('/economyMan/home', {});
};
