import createHttp from "@/uni_modules/base-plugin/utils/request";

export const getZgmCommunityDetail = (data: any) => {
  return createHttp.get("/zgmCommunity/getDetail", data);
};
export const getZgmCommunityList = (data: any) => {
  return createHttp.get("/zgmCommunity/getList", data);
};
export const getPlaceList = (data: any) => {
  return createHttp.get("/zgmCommunity/getPlaceList", data);
};

export const getConmunitySkillerList = (data: any) => {
  return createHttp.get("/skiller/getConmunitySkillerList", data);
};
export const getAddCommunity = (data: any) => {
  return createHttp.get("/zgmCommunity/addCommunity", data);
};
