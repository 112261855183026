import createHttp from '@/uni_modules/base-plugin/utils/request';

export const getDetail = (id: string) => {
    return createHttp.get('/clerk/getDetail', { id });
};

export const getList = (data: any) => {
    return createHttp.get('/collect/getList', data);
};

export const getCompanyQRCode = (data: any) => {
    return createHttp.get('/clerk/companyImg', data);
};

export const getclerkupdate = (data: any) => {
    return createHttp.post('/clerk/updateXCX', data);
};

export const getSkillerQRCode = (data: any) => {
    return createHttp.get('/clerk/skillerImg', data);
};

export const getOddStationQRCode = (data: any) => {
    return createHttp.get('/clerk/oddStationImg', data);
};

export const getSkillerList = (data: any) => {
    return createHttp.get('/skiller/inviteList', data);
};
export const getCompanyQRCodeSave = (data: any) => {
    return createHttp.post('/clerk/companyImgSave', data);
};
export const getDownloadImg = (data: any, type: string) => {
    let url = '/clerk/skillerDownloadImg';
    if (type == 'company') {
        url = '/clerk/companyDownloadImg';
    } else if (type == 'oddStation') {
        url = '/clerk/OddStationBcImg';
    } else {
        url = '/clerk/skillerDownloadImg';
    }
    return createHttp.post(url, data);
};
export const getClerkId = (data: any) => {
    return createHttp.get('/clerk/getClerkId', data);
};
export const getClerkDetail = (id: any) => {
    return createHttp.get('/clerk/detail', id);
};

export const getClerkDetailById = (id: any) => {
    return createHttp.get('/clerk/getDetail', id);
};

export const getClerkByName = (address:string,name:string) => {
    return createHttp.get('/clerk/getClerkByName', {
        address:address,
        name:name
    });
};

export const update = (data: any) => {
    return createHttp.post('/clerk/update', data);
};
export const save = (data: any) => {
    return createHttp.post('/clerk/save', data);
};
export const saveSite = (data: any) => {
    return createHttp.post('/clerk/saveSite', data);
};
export const del = (data: any) => {
    return createHttp.post('/clerk/del', data);
};
export const saveGuidance = (data: any) => {
    return createHttp.post('/clerk/saveGuidance', data);
};
export const saveGuidanceSiteId = (data: any) => {
    return createHttp.post('/clerk/saveGuidanceSiteId', data);
};
export const updateGuidance = (data: any) => {
    return createHttp.post('/clerk/updateGuidance', data);
};
export const delGuidance = (data: any) => {
    return createHttp.get('/clerk/delGuidance',data);
};

export const getDetailGet = (id: string) => {
    return createHttp.get('/clerk/detail', { id: id });
};

export const findHomeItem = () => {
    return createHttp.get('/clerk/findHomeItem', null);
};
export const findStand = (params:any) => {
    return createHttp.get('/clerk/findStand', params);
};
export const findStandPerson = (params:any) => {
    return createHttp.get('/clerk/findStandPerson', params);
};
export const findStandInformation = (params:any) => {
    return createHttp.get('/clerk/findStandInformation', params);
};
export const findStandCompany = (params:any) => {
    return createHttp.get('/clerk/findStandCompany', params);
};
export const findStandWork = (params:any) => {
    return createHttp.get('/clerk/findStandWork', params);
};

export const findStandLiaison = (params:any) => {
    return createHttp.get('/clerk/findStandLiaison', params);
};
