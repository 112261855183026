import createHttp from '@/uni_modules/base-plugin/utils/request';
export const findSchoolAll = (params: any) => {
    return createHttp.get('/school/index', params);
};
export const findOnEnterprisePage = (params: any) => {
    return createHttp.get('/school/findOnEnterprisePage', params);
};
export const findAllMajor = (params: any) => {
    return createHttp.get('/school/findAllMajor', params);
};

export const findOneSchool = (params: any) => {
    return createHttp.get('/school/findOneSchool', params);
};

export const findMajorId = (params: any) => {
    return createHttp.get('/major/findMajorId', params);
};


export const addSchoolEnterprise = (params: any) => {
    return createHttp.get('/school/addSchoolEnterprise', params);
};


export const findAllCompany = (params: any) => {
    return createHttp.get('/SchoolEnterprise/findAllCompany', params);
};

export const findAllWork = (params: any) => {
    return createHttp.get('/SchoolEnterprise/findAllWork', params);
};
