import createHttp from '@/uni_modules/base-plugin/utils/request';

export const getList = (data: any) => {
    return createHttp.get('/oddStation/getList', data.data);
};

export const getListTwo = (data: any) => {
    return createHttp.get('/oddStation/getList', data);
};

export const getDetail = (data: any) => {
    return createHttp.get('/oddStation/detailsLists', data).then((res: any) => {
        return res;
    });
};

export const getUnoccupiedCountList = (data: any) => {
    return createHttp.get('/oddStation/unoccupiedCount', data);
};

export const oddSkillerList = (data: any) => {
    return createHttp.post('/oddStation/oddSkillerList', data);
};

export const oddWorkList = (data: any) => {
    return createHttp.post('/oddStation/oddWorkList', data);
};

export const getOddStationDetail = (data: any) => {
    return createHttp.get('/oddStation/detailsLists', data).then((res: any) => {
        return res;
    });
};

export const getOddStationDetailQRCode = (data: any) => {
    return createHttp.get('/oddStation/getOddStationDetailQRCode', data);
};

export const geteditRecruitmentEvent = (data: any) => {
    return createHttp.post('/oddStation/oddUpdate', data);
};

export const getUpdateSkillerOddStation = (data: any) => {
    return createHttp.post('/skiller/skillerUpdate', data);
};

export const oddSxWorkList = (data: any) => {
    return createHttp.post('/workservice/workSx', data);
};

export const getOddStationPosition = (data: any) => {
    return createHttp.post('/oddStation/getOddStationPosition', data);
};

export const getSiteList = (data: any) => {
    return createHttp.get('/site/getSiteList', data);
};

export const getMapSiteList = (data: any) => {
    return createHttp.get('/site/getMapSiteList', data);
};

export const getSitePtList = (data: any) => {
    return createHttp.get('/site/getSitePtList', data);
};

export const getOddStationWorkCount = (data: any) => {
    return createHttp.get('/oddStation/getOddStationWorkCount', data);
};

export const getOddStationQfWorkCount = (data: any) => {
    return createHttp.get('/oddStation/getOddStationQfWorkCount', data);
};
