/* tslint:disable */
/* eslint-disable */

export interface ExercisesResponse {
    exercises: Exercise[];
}

export interface LearnResponse {
    exercises: Exercise[];
    list: WordListVo;
}

export interface MessageResponse {
    message: string;
}

/**
 * @ClassName CodePayOrder
 * @author dbw
 * @date 2023/08/09 16:08
 */
export interface CodePayOrder {
    id: string;
    payNo: string;
    payId: string;
    payTag: string;
    money: number;
    upTime: Date;
}

export interface Document {
    id: string;
    image: string;
    title: string;
    createTime: number;
    updateTime: number;
    userId: string;
    type: number;
    scale: number;
    fromUrl: string;
    state: number;
    noteContent: string;
    videoUrl: string;
    lines: Line[];
}

export interface DocumentToDocumentRequestMapper__2 extends BaseMapper<Document, DocumentRequest> {
}

export interface Ext {
}

export interface Extractor {
    id: string;
    word: string;
    position: number;
    length: number;
    lineId: string;
}

export interface Hierarchy {
    children: MaterialTagChildren[];
    ext: Ext;
    hierarchyName: string;
}

export interface Line {
    id: string;
    points: string;
    sort: number;
    words: string;
    docId: string;
    userId: string;
    createTime: number;
    modifyTime: number;
    parentId: string;
    timestamp: string;
    docType: number;
    image: string;
    lang: string;
    range: string;
    extractor: string;
    ocrChar: string;
    extractors: Extractor[];
    ocrChars: OcrChar[];
    childes: Line[];
    editState: number;
}

export interface LuckProduct {
    id: string;
    /**
     * 商品名
     */
    name: string;
    /**
     * 概率
     */
    weight: number;
    /**
     * 是否展示
     */
    flag: number;
}

/**
 * 用户表
 * @author dbw
 * @since 2023-08-09
 */
export interface LuckUser {
    id: string;
    /**
     * 用户名
     */
    uname: string;
    /**
     * 省份
     */
    province: string;
    /**
     * 城市
     */
    city: string;
    /**
     * 详细地址
     */
    address: string;
    /**
     * 电话
     */
    tel: string;
    /**
     * 可抽奖次数
     */
    luckNum: number;
    /**
     * 手机号
     */
    phone: string;
    /**
     * 密码
     */
    password: string;
}

export interface LuckUserProduct {
    id: string;
    uid: string;
    pid: number;
    /**
     * 是否兑换 0未发货  1发货了
     */
    exchange: number;
    ltime: Date;
    pname: string;
}

export interface MaterialTag {
    tagPath: string;
    hierarchies: Hierarchy[];
    ext: Ext;
}

export interface MaterialTagChildren {
    tagId: string;
    tagDimensionId: string;
    ext: string;
    tagDescription: string;
    tagName: string;
    hierarchies: Hierarchy[];
}

export interface OcrChar {
    id: number;
    charStr: string;
    points: string;
    sort: number;
    lineId: number;
}

export interface Session {
    id: string;
    listId: string;
    type: string;
    score: number;
    currentExerciseIndex: number;
    createdAt: Date;
    completedAt: Date;
}

export interface UserAction {
    id: string;
    createTime: number;
    docId: string;
    editState: number;
    patch: string;
    userId: string;
}

export interface Word {
    id: string;
    listId: string;
    value: string;
    meaning: string;
    createdAt: Date;
}

export interface WordList {
    id: string;
    userId: string;
    name: string;
    description: string;
    context: string;
    createdAt: Date;
    words: Word[];
}

export interface Exercise {
    wordId: string;
    type: string;
    question: string;
    options: string[];
    correctAnswer: string;
}

export interface WordListVo {
    id: string;
    name: string;
    context: string;
}

/**
 * 响应信息主体
 * @author Lion Li
 */
export interface R<T> extends Serializable {
    code: number;
    msg: string;
    data: T;
}

export interface QuizResponse {
    questions: Question[];
    list: QuizListVo;
    totalQuestions: number;
    completed: boolean;
}

export interface WordRequest {
    word: string;
    listId: string;
}

export interface CreateListRequest {
    name: string;
    description: string;
    context: string;
}

export interface TokenRequest {
    email: string;
    password: string;
    gotrueMetaSecurity: { [index: string]: any };
}

export interface SignUpRequest {
    email: string;
    password: string;
    data: { [index: string]: any };
    gotrueMetaSecurity: { [index: string]: any };
    codeChallenge: string;
    codeChallengeMethod: string;
}

export interface Page<T> extends Serializable {
    records: T[];
    pageNumber: number;
    pageSize: number;
    totalPage: number;
    totalRow: number;
    optimizeCountQuery: boolean;
}

export interface Serializable {
}

export interface Question {
    wordId: string;
    type: string;
    question: string;
    options: string[];
    correctAnswer: string;
}

export interface QuizListVo {
    id: string;
    name: string;
    context: string;
}

export interface BaseMapper<S, T> {
}

export interface DocumentRequest {
    id: string;
    image: string;
    title: string;
    createTime: number;
    updateTime: number;
    userId: string;
    type: number;
    scale: number;
    fromUrl: string;
    state: number;
    noteContent: string;
    videoUrl: string;
    lines: Line[];
}

export interface RestApplication {

    /**
     * HTTP GET /12
     * Java method: cn.dbw.luckwheel.controller.IndexController.index
     */
    index(): RestResponse<any>;

    /**
     * 生成临时 token
     * @param userId 用户ID
     * @return 生成的 token
     * HTTP POST /auth/v1/generateToken
     * Java method: cn.dbw.luckwheel.controller.v1.AuthController.generateToken
     */
    generateToken(queryParams: { userId: string; }): RestResponse<R<{ [index: string]: string }>>;

    /**
     * HTTP GET /auth/v1/info
     * Java method: cn.dbw.luckwheel.controller.v1.AuthController.info
     */
    info(): RestResponse<string>;

    /**
     * 登录
     * @param username 用户名
     * @param password 密码
     * @return
     * HTTP POST /auth/v1/login
     * Java method: cn.dbw.luckwheel.controller.v1.AuthController.login
     */
    login(queryParams: { username: string; password: string; }): RestResponse<R<string>>;

    /**
     * 登录
     * @param email 邮箱
     * @param password 密码
     * @return
     * HTTP POST /auth/v1/signIn
     * Java method: cn.dbw.luckwheel.controller.v1.AuthController.signIn
     */
    signIn(queryParams: { email: string; password: string; }): RestResponse<R<string>>;

    /**
     * 登出
     * @return
     * HTTP POST /auth/v1/signOut
     * Java method: cn.dbw.luckwheel.controller.v1.AuthController.signOut
     */
    signOut(): RestResponse<R<string>>;

    /**
     * 注册
     * @param signUpRequest 注册请求对象
     * @return
     * HTTP POST /auth/v1/signUp
     * Java method: cn.dbw.luckwheel.controller.v1.AuthController.signUp
     */
    signUp(signUpRequest: SignUpRequest): RestResponse<R<string>>;

    /**
     * HTTP POST /auth/v1/token
     * Java method: cn.dbw.luckwheel.controller.v1.AuthController.getToken
     */
    getToken(tokenRequest: TokenRequest, queryParams: { grant_type: string; }): RestResponse<R<{ [index: string]: any }>>;

    /**
     * 根据主键获取详细信息。
     * @param id 主键
     * @return 详情
     * HTTP GET /extractor/getInfo/{id}
     * Java method: cn.dbw.luckwheel.controller.ExtractorController.getInfo
     */
    getInfo$GET$extractor_getInfo_id(id: string): RestResponse<Extractor>;

    /**
     * 查询所有。
     * @return 所有数据
     * HTTP GET /extractor/list
     * Java method: cn.dbw.luckwheel.controller.ExtractorController.list
     */
    list$GET$extractor_list(): RestResponse<Extractor[]>;

    /**
     * 分页查询。
     * @param page 分页对象
     * @return 分页对象
     * HTTP GET /extractor/page
     * Java method: cn.dbw.luckwheel.controller.ExtractorController.page
     */
    page$GET$extractor_page(): RestResponse<Page<Extractor>>;

    /**
     * 根据主键删除。
     * @param id 主键
     * @return {@code true} 删除成功，{@code false} 删除失败
     * HTTP DELETE /extractor/remove/{id}
     * Java method: cn.dbw.luckwheel.controller.ExtractorController.remove
     */
    remove$DELETE$extractor_remove_id(id: string): RestResponse<boolean>;

    /**
     * 添加。
     * @param extractor
     * @return {@code true} 添加成功，{@code false} 添加失败
     * HTTP POST /extractor/save
     * Java method: cn.dbw.luckwheel.controller.ExtractorController.save
     */
    save$POST$extractor_save(extractor: Extractor): RestResponse<boolean>;

    /**
     * 根据主键更新。
     * @param extractor
     * @return {@code true} 更新成功，{@code false} 更新失败
     * HTTP PUT /extractor/update
     * Java method: cn.dbw.luckwheel.controller.ExtractorController.update
     */
    update$PUT$extractor_update(extractor: Extractor): RestResponse<boolean>;

    /**
     * HTTP GET /learn/getMoreExercises/{listId}
     * Java method: cn.dbw.luckwheel.controller.LearnController.getMoreExercises
     */
    getMoreExercises(listId: string): RestResponse<any>;

    /**
     * HTTP GET /learn/startLearning/{listId}
     * Java method: cn.dbw.luckwheel.controller.LearnController.startLearning
     */
    startLearning(listId: string): RestResponse<any>;

    /**
     * 根据主键获取详细信息。
     * @param id 主键
     * @return 详情
     * HTTP GET /line/getInfo/{id}
     * Java method: cn.dbw.luckwheel.controller.LineController.getInfo
     */
    getInfo$GET$line_getInfo_id(id: string): RestResponse<Line>;

    /**
     * 查询所有。
     * @return 所有数据
     * HTTP GET /line/list
     * Java method: cn.dbw.luckwheel.controller.LineController.list
     */
    list$GET$line_list(): RestResponse<Line[]>;

    /**
     * 分页查询。
     * @param page 分页对象
     * @return 分页对象
     * HTTP GET /line/page
     * Java method: cn.dbw.luckwheel.controller.LineController.page
     */
    page$GET$line_page(): RestResponse<Page<Line>>;

    /**
     * 根据主键删除。
     * @param id 主键
     * @return {@code true} 删除成功，{@code false} 删除失败
     * HTTP DELETE /line/remove/{id}
     * Java method: cn.dbw.luckwheel.controller.LineController.remove
     */
    remove$DELETE$line_remove_id(id: string): RestResponse<boolean>;

    /**
     * 添加。
     * @param line
     * @return {@code true} 添加成功，{@code false} 添加失败
     * HTTP POST /line/save
     * Java method: cn.dbw.luckwheel.controller.LineController.save
     */
    save$POST$line_save(line: Line): RestResponse<boolean>;

    /**
     * 根据主键更新。
     * @param line
     * @return {@code true} 更新成功，{@code false} 更新失败
     * HTTP PUT /line/update
     * Java method: cn.dbw.luckwheel.controller.LineController.update
     */
    update$PUT$line_update(line: Line): RestResponse<boolean>;

    /**
     * HTTP GET /lup/lkall
     * Java method: cn.dbw.luckwheel.controller.LuckUserProductController.getAllLuckPerson
     */
    getAllLuckPerson(): RestResponse<R<LuckUserProduct[]>>;

    /**
     * HTTP GET /lup/lkp
     * Java method: cn.dbw.luckwheel.controller.LuckUserProductController.getLuckPersonByUid
     */
    getLuckPersonByUid(queryParams: { uid: string; }): RestResponse<R<LuckUserProduct[]>>;

    /**
     * HTTP GET /lup/update
     * Java method: cn.dbw.luckwheel.controller.LuckUserProductController.update
     */
    update$GET$lup_update(): RestResponse<R<any>>;

    /**
     * 根据主键获取详细信息。
     * @param id 主键
     * @return 详情
     * HTTP GET /materialTagChildren/getInfo/{id}
     * Java method: cn.dbw.luckwheel.controller.MaterialTagChildrenController.getInfo
     */
    getInfo$GET$materialTagChildren_getInfo_id(id: string): RestResponse<MaterialTagChildren>;

    /**
     * 查询所有。
     * @return 所有数据
     * HTTP GET /materialTagChildren/list
     * Java method: cn.dbw.luckwheel.controller.MaterialTagChildrenController.list
     */
    list$GET$materialTagChildren_list(): RestResponse<MaterialTagChildren[]>;

    /**
     * 分页查询。
     * @param page 分页对象
     * @return 分页对象
     * HTTP GET /materialTagChildren/page
     * Java method: cn.dbw.luckwheel.controller.MaterialTagChildrenController.page
     */
    page$GET$materialTagChildren_page(): RestResponse<Page<MaterialTagChildren>>;

    /**
     * 根据主键删除。
     * @param id 主键
     * @return {@code true} 删除成功，{@code false} 删除失败
     * HTTP DELETE /materialTagChildren/remove/{id}
     * Java method: cn.dbw.luckwheel.controller.MaterialTagChildrenController.remove
     */
    remove$DELETE$materialTagChildren_remove_id(id: string): RestResponse<boolean>;

    /**
     * 添加。
     * @param materialTagChildren
     * @return {@code true} 添加成功，{@code false} 添加失败
     * HTTP POST /materialTagChildren/save
     * Java method: cn.dbw.luckwheel.controller.MaterialTagChildrenController.save
     */
    save$POST$materialTagChildren_save(materialTagChildren: MaterialTagChildren): RestResponse<boolean>;

    /**
     * 根据主键更新。
     * @param materialTagChildren
     * @return {@code true} 更新成功，{@code false} 更新失败
     * HTTP PUT /materialTagChildren/update
     * Java method: cn.dbw.luckwheel.controller.MaterialTagChildrenController.update
     */
    update$PUT$materialTagChildren_update(materialTagChildren: MaterialTagChildren): RestResponse<boolean>;

    /**
     * 根据主键获取详细信息。
     * @param id 主键
     * @return 详情
     * HTTP GET /ocrChar/getInfo/{id}
     * Java method: cn.dbw.luckwheel.controller.OcrCharController.getInfo
     */
    getInfo$GET$ocrChar_getInfo_id(id: number): RestResponse<OcrChar>;

    /**
     * 查询所有。
     * @return 所有数据
     * HTTP GET /ocrChar/list
     * Java method: cn.dbw.luckwheel.controller.OcrCharController.list
     */
    list$GET$ocrChar_list(): RestResponse<OcrChar[]>;

    /**
     * 分页查询。
     * @param page 分页对象
     * @return 分页对象
     * HTTP GET /ocrChar/page
     * Java method: cn.dbw.luckwheel.controller.OcrCharController.page
     */
    page$GET$ocrChar_page(): RestResponse<Page<OcrChar>>;

    /**
     * 根据主键删除。
     * @param id 主键
     * @return {@code true} 删除成功，{@code false} 删除失败
     * HTTP DELETE /ocrChar/remove/{id}
     * Java method: cn.dbw.luckwheel.controller.OcrCharController.remove
     */
    remove$DELETE$ocrChar_remove_id(id: number): RestResponse<boolean>;

    /**
     * 添加。
     * @param ocrChar
     * @return {@code true} 添加成功，{@code false} 添加失败
     * HTTP POST /ocrChar/save
     * Java method: cn.dbw.luckwheel.controller.OcrCharController.save
     */
    save$POST$ocrChar_save(ocrChar: OcrChar): RestResponse<boolean>;

    /**
     * 根据主键更新。
     * @param ocrChar
     * @return {@code true} 更新成功，{@code false} 更新失败
     * HTTP PUT /ocrChar/update
     * Java method: cn.dbw.luckwheel.controller.OcrCharController.update
     */
    update$PUT$ocrChar_update(ocrChar: OcrChar): RestResponse<boolean>;

    /**
     * HTTP GET /product/add
     * Java method: cn.dbw.luckwheel.controller.LuckProductController.add
     */
    add(map: LuckProduct): RestResponse<R<boolean>>;

    /**
     * HTTP GET /product/lists
     * Java method: cn.dbw.luckwheel.controller.LuckProductController.getLuckProductList
     */
    getLuckProductList(): RestResponse<R<LuckProduct[]>>;

    /**
     * 获取奖品列表
     * @return R<List<LuckProduct>> 奖品列表
     * HTTP GET /product/showlists
     * Java method: cn.dbw.luckwheel.controller.LuckProductController.getLuckProductByShowList
     */
    getLuckProductByShowList(): RestResponse<R<string[]>>;

    /**
     * HTTP GET /product/update
     * Java method: cn.dbw.luckwheel.controller.LuckProductController.update
     */
    update$GET$product_update(map: LuckProduct): RestResponse<R<boolean>>;

    /**
     * HTTP GET /quiz/getMoreQuestions/{listId}
     * Java method: cn.dbw.luckwheel.controller.QuizController.getMoreQuestions
     */
    getMoreQuestions(listId: string): RestResponse<Question[]>;

    /**
     * HTTP GET /quiz/startQuiz/{listId}
     * Java method: cn.dbw.luckwheel.controller.QuizController.startQuiz
     */
    startQuiz(listId: string): RestResponse<QuizResponse>;

    /**
     * HTTP PUT /user
     * Java method: cn.dbw.luckwheel.controller.LuckUserController.updateUser
     */
    updateUser(user: LuckUser): RestResponse<R<any>>;

    /**
     * 添加用户
     * @param user
     * @RequestBody LuckUser user
     * @return
     * HTTP POST /user/add
     * Java method: cn.dbw.luckwheel.controller.LuckUserController.addUser
     */
    addUser(user: LuckUser): RestResponse<R<any>>;

    /**
     * 获取用户列表
     * @param page
     * @param size
     * HTTP GET /user/list
     * Java method: cn.dbw.luckwheel.controller.LuckUserController.getUserList
     */
    getUserList(queryParams?: { page?: number; size?: number; }): RestResponse<R<LuckUser[]>>;

    /**
     * HTTP POST /user/register
     * Java method: cn.dbw.luckwheel.controller.LuckUserController.register
     */
    register(user: LuckUser): RestResponse<R<any>>;

    /**
     * HTTP DELETE /user/{id}
     * Java method: cn.dbw.luckwheel.controller.LuckUserController.deleteUser
     */
    deleteUser(id: number): RestResponse<R<any>>;

    /**
     * HTTP GET /user/{id}
     * Java method: cn.dbw.luckwheel.controller.LuckUserController.getUserInfo
     */
    getUserInfo(id: number): RestResponse<R<any>>;

    /**
     * 根据主键获取详细信息。
     * @param id 主键
     * @return 详情
     * HTTP GET /userAction/getInfo/{id}
     * Java method: cn.dbw.luckwheel.controller.UserActionController.getInfo
     */
    getInfo$GET$userAction_getInfo_id(id: string): RestResponse<UserAction>;

    /**
     * 查询所有。
     * @return 所有数据
     * HTTP GET /userAction/list
     * Java method: cn.dbw.luckwheel.controller.UserActionController.list
     */
    list$GET$userAction_list(): RestResponse<UserAction[]>;

    /**
     * 分页查询。
     * @param page 分页对象
     * @return 分页对象
     * HTTP GET /userAction/page
     * Java method: cn.dbw.luckwheel.controller.UserActionController.page
     */
    page$GET$userAction_page(): RestResponse<Page<UserAction>>;

    /**
     * 根据主键删除。
     * @param id 主键
     * @return {@code true} 删除成功，{@code false} 删除失败
     * HTTP DELETE /userAction/remove/{id}
     * Java method: cn.dbw.luckwheel.controller.UserActionController.remove
     */
    remove$DELETE$userAction_remove_id(id: string): RestResponse<boolean>;

    /**
     * 添加。
     * @param userAction
     * @return {@code true} 添加成功，{@code false} 添加失败
     * HTTP POST /userAction/save
     * Java method: cn.dbw.luckwheel.controller.UserActionController.save
     */
    save$POST$userAction_save(userAction: UserAction): RestResponse<boolean>;

    /**
     * 根据主键更新。
     * @param userAction
     * @return {@code true} 更新成功，{@code false} 更新失败
     * HTTP PUT /userAction/update
     * Java method: cn.dbw.luckwheel.controller.UserActionController.update
     */
    update$PUT$userAction_update(userAction: UserAction): RestResponse<boolean>;

    /**
     * HTTP GET /v1/document
     * Java method: cn.dbw.luckwheel.controller.DocumentController.getDocumentList
     */
    getDocumentList(queryParams: { modifyTime: number; page: number; }): RestResponse<Document[]>;

    /**
     * HTTP GET /wheel/lottery
     * Java method: cn.dbw.luckwheel.controller.LuckWheelController.wheelLottery
     */
    wheelLottery(): RestResponse<R<string>>;

    /**
     * HTTP GET /wheel/test
     * Java method: cn.dbw.luckwheel.controller.LuckWheelController.canDog
     */
    canDog(): RestResponse<boolean>;

    /**
     * 获取单词列表。
     * @param listId 列表ID
     * @return 单词列表
     * HTTP GET /word/list/{listId}
     * Java method: cn.dbw.luckwheel.controller.WordController.getWords
     */
    getWords(listId: number): RestResponse<Word[]>;

    /**
     * 删除单词。
     * @param listId 列表ID
     * @param wordId 单词ID
     * @return 删除结果
     * HTTP DELETE /word/remove/{listId}/{wordId}
     * Java method: cn.dbw.luckwheel.controller.WordController.deleteWord
     */
    deleteWord(listId: number, wordId: number): RestResponse<void>;

    /**
     * 添加单词。
     * @param wordRequest 单词对象
     * @return 添加的单词对象
     * HTTP POST /word/save
     * Java method: cn.dbw.luckwheel.controller.WordController.addWord
     */
    addWord(wordRequest: WordRequest): RestResponse<Word>;

    /**
     * HTTP POST /wordList
     * Java method: cn.dbw.luckwheel.controller.WordListController.createList
     */
    createList(request: CreateListRequest): RestResponse<WordList>;

    /**
     * HTTP GET /wordList
     * Java method: cn.dbw.luckwheel.controller.WordListController.getLists
     */
    getLists(): RestResponse<WordList[]>;

    /**
     * HTTP DELETE /wordList/{id}
     * Java method: cn.dbw.luckwheel.controller.WordListController.deleteList
     */
    deleteList(id: string): RestResponse<void>;

    /**
     * HTTP GET /wordList/{id}
     * Java method: cn.dbw.luckwheel.controller.WordListController.getList
     */
    getList(id: string): RestResponse<WordList>;

    /**
     * HTTP PUT /wordList/{id}
     * Java method: cn.dbw.luckwheel.controller.WordListController.updateList
     */
    updateList(id: string, request: CreateListRequest): RestResponse<WordList>;
}

export interface HttpClient {

    request<R>(requestConfig: { method: string; url: string; queryParams?: any; data?: any; copyFn?: (data: R) => R; }): RestResponse<R>;
}

export class RestApplicationClient implements RestApplication {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /12
     * Java method: cn.dbw.luckwheel.controller.IndexController.index
     */
    index(): RestResponse<any> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`12` });
    }

    /**
     * 生成临时 token
     * @param userId 用户ID
     * @return 生成的 token
     * HTTP POST /auth/v1/generateToken
     * Java method: cn.dbw.luckwheel.controller.v1.AuthController.generateToken
     */
    generateToken(queryParams: { userId: string; }): RestResponse<R<{ [index: string]: string }>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`auth/v1/generateToken`, queryParams: queryParams });
    }

    /**
     * HTTP GET /auth/v1/info
     * Java method: cn.dbw.luckwheel.controller.v1.AuthController.info
     */
    info(): RestResponse<string> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`auth/v1/info` });
    }

    /**
     * 登录
     * @param username 用户名
     * @param password 密码
     * @return
     * HTTP POST /auth/v1/login
     * Java method: cn.dbw.luckwheel.controller.v1.AuthController.login
     */
    login(queryParams: { username: string; password: string; }): RestResponse<R<string>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`auth/v1/login`, queryParams: queryParams });
    }

    /**
     * 登录
     * @param email 邮箱
     * @param password 密码
     * @return
     * HTTP POST /auth/v1/signIn
     * Java method: cn.dbw.luckwheel.controller.v1.AuthController.signIn
     */
    signIn(queryParams: { email: string; password: string; }): RestResponse<R<string>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`auth/v1/signIn`, queryParams: queryParams });
    }

    /**
     * 登出
     * @return
     * HTTP POST /auth/v1/signOut
     * Java method: cn.dbw.luckwheel.controller.v1.AuthController.signOut
     */
    signOut(): RestResponse<R<string>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`auth/v1/signOut` });
    }

    /**
     * 注册
     * @param signUpRequest 注册请求对象
     * @return
     * HTTP POST /auth/v1/signUp
     * Java method: cn.dbw.luckwheel.controller.v1.AuthController.signUp
     */
    signUp(signUpRequest: SignUpRequest): RestResponse<R<string>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`auth/v1/signUp`, data: signUpRequest });
    }

    /**
     * HTTP POST /auth/v1/token
     * Java method: cn.dbw.luckwheel.controller.v1.AuthController.getToken
     */
    getToken(tokenRequest: TokenRequest, queryParams: { grant_type: string; }): RestResponse<R<{ [index: string]: any }>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`auth/v1/token`, queryParams: queryParams, data: tokenRequest });
    }

    /**
     * 根据主键获取详细信息。
     * @param id 主键
     * @return 详情
     * HTTP GET /extractor/getInfo/{id}
     * Java method: cn.dbw.luckwheel.controller.ExtractorController.getInfo
     */
    getInfo$GET$extractor_getInfo_id(id: string): RestResponse<Extractor> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`extractor/getInfo/${id}` });
    }

    /**
     * 查询所有。
     * @return 所有数据
     * HTTP GET /extractor/list
     * Java method: cn.dbw.luckwheel.controller.ExtractorController.list
     */
    list$GET$extractor_list(): RestResponse<Extractor[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`extractor/list` });
    }

    /**
     * 分页查询。
     * @param page 分页对象
     * @return 分页对象
     * HTTP GET /extractor/page
     * Java method: cn.dbw.luckwheel.controller.ExtractorController.page
     */
    page$GET$extractor_page(): RestResponse<Page<Extractor>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`extractor/page` });
    }

    /**
     * 根据主键删除。
     * @param id 主键
     * @return {@code true} 删除成功，{@code false} 删除失败
     * HTTP DELETE /extractor/remove/{id}
     * Java method: cn.dbw.luckwheel.controller.ExtractorController.remove
     */
    remove$DELETE$extractor_remove_id(id: string): RestResponse<boolean> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`extractor/remove/${id}` });
    }

    /**
     * 添加。
     * @param extractor
     * @return {@code true} 添加成功，{@code false} 添加失败
     * HTTP POST /extractor/save
     * Java method: cn.dbw.luckwheel.controller.ExtractorController.save
     */
    save$POST$extractor_save(extractor: Extractor): RestResponse<boolean> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`extractor/save`, data: extractor });
    }

    /**
     * 根据主键更新。
     * @param extractor
     * @return {@code true} 更新成功，{@code false} 更新失败
     * HTTP PUT /extractor/update
     * Java method: cn.dbw.luckwheel.controller.ExtractorController.update
     */
    update$PUT$extractor_update(extractor: Extractor): RestResponse<boolean> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`extractor/update`, data: extractor });
    }

    /**
     * HTTP GET /learn/getMoreExercises/{listId}
     * Java method: cn.dbw.luckwheel.controller.LearnController.getMoreExercises
     */
    getMoreExercises(listId: string): RestResponse<any> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`learn/getMoreExercises/${listId}` });
    }

    /**
     * HTTP GET /learn/startLearning/{listId}
     * Java method: cn.dbw.luckwheel.controller.LearnController.startLearning
     */
    startLearning(listId: string): RestResponse<any> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`learn/startLearning/${listId}` });
    }

    /**
     * 根据主键获取详细信息。
     * @param id 主键
     * @return 详情
     * HTTP GET /line/getInfo/{id}
     * Java method: cn.dbw.luckwheel.controller.LineController.getInfo
     */
    getInfo$GET$line_getInfo_id(id: string): RestResponse<Line> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`line/getInfo/${id}` });
    }

    /**
     * 查询所有。
     * @return 所有数据
     * HTTP GET /line/list
     * Java method: cn.dbw.luckwheel.controller.LineController.list
     */
    list$GET$line_list(): RestResponse<Line[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`line/list` });
    }

    /**
     * 分页查询。
     * @param page 分页对象
     * @return 分页对象
     * HTTP GET /line/page
     * Java method: cn.dbw.luckwheel.controller.LineController.page
     */
    page$GET$line_page(): RestResponse<Page<Line>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`line/page` });
    }

    /**
     * 根据主键删除。
     * @param id 主键
     * @return {@code true} 删除成功，{@code false} 删除失败
     * HTTP DELETE /line/remove/{id}
     * Java method: cn.dbw.luckwheel.controller.LineController.remove
     */
    remove$DELETE$line_remove_id(id: string): RestResponse<boolean> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`line/remove/${id}` });
    }

    /**
     * 添加。
     * @param line
     * @return {@code true} 添加成功，{@code false} 添加失败
     * HTTP POST /line/save
     * Java method: cn.dbw.luckwheel.controller.LineController.save
     */
    save$POST$line_save(line: Line): RestResponse<boolean> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`line/save`, data: line });
    }

    /**
     * 根据主键更新。
     * @param line
     * @return {@code true} 更新成功，{@code false} 更新失败
     * HTTP PUT /line/update
     * Java method: cn.dbw.luckwheel.controller.LineController.update
     */
    update$PUT$line_update(line: Line): RestResponse<boolean> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`line/update`, data: line });
    }

    /**
     * HTTP GET /lup/lkall
     * Java method: cn.dbw.luckwheel.controller.LuckUserProductController.getAllLuckPerson
     */
    getAllLuckPerson(): RestResponse<R<LuckUserProduct[]>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`lup/lkall` });
    }

    /**
     * HTTP GET /lup/lkp
     * Java method: cn.dbw.luckwheel.controller.LuckUserProductController.getLuckPersonByUid
     */
    getLuckPersonByUid(queryParams: { uid: string; }): RestResponse<R<LuckUserProduct[]>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`lup/lkp`, queryParams: queryParams });
    }

    /**
     * HTTP GET /lup/update
     * Java method: cn.dbw.luckwheel.controller.LuckUserProductController.update
     */
    update$GET$lup_update(): RestResponse<R<any>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`lup/update` });
    }

    /**
     * 根据主键获取详细信息。
     * @param id 主键
     * @return 详情
     * HTTP GET /materialTagChildren/getInfo/{id}
     * Java method: cn.dbw.luckwheel.controller.MaterialTagChildrenController.getInfo
     */
    getInfo$GET$materialTagChildren_getInfo_id(id: string): RestResponse<MaterialTagChildren> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`materialTagChildren/getInfo/${id}` });
    }

    /**
     * 查询所有。
     * @return 所有数据
     * HTTP GET /materialTagChildren/list
     * Java method: cn.dbw.luckwheel.controller.MaterialTagChildrenController.list
     */
    list$GET$materialTagChildren_list(): RestResponse<MaterialTagChildren[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`materialTagChildren/list` });
    }

    /**
     * 分页查询。
     * @param page 分页对象
     * @return 分页对象
     * HTTP GET /materialTagChildren/page
     * Java method: cn.dbw.luckwheel.controller.MaterialTagChildrenController.page
     */
    page$GET$materialTagChildren_page(): RestResponse<Page<MaterialTagChildren>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`materialTagChildren/page` });
    }

    /**
     * 根据主键删除。
     * @param id 主键
     * @return {@code true} 删除成功，{@code false} 删除失败
     * HTTP DELETE /materialTagChildren/remove/{id}
     * Java method: cn.dbw.luckwheel.controller.MaterialTagChildrenController.remove
     */
    remove$DELETE$materialTagChildren_remove_id(id: string): RestResponse<boolean> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`materialTagChildren/remove/${id}` });
    }

    /**
     * 添加。
     * @param materialTagChildren
     * @return {@code true} 添加成功，{@code false} 添加失败
     * HTTP POST /materialTagChildren/save
     * Java method: cn.dbw.luckwheel.controller.MaterialTagChildrenController.save
     */
    save$POST$materialTagChildren_save(materialTagChildren: MaterialTagChildren): RestResponse<boolean> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`materialTagChildren/save`, data: materialTagChildren });
    }

    /**
     * 根据主键更新。
     * @param materialTagChildren
     * @return {@code true} 更新成功，{@code false} 更新失败
     * HTTP PUT /materialTagChildren/update
     * Java method: cn.dbw.luckwheel.controller.MaterialTagChildrenController.update
     */
    update$PUT$materialTagChildren_update(materialTagChildren: MaterialTagChildren): RestResponse<boolean> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`materialTagChildren/update`, data: materialTagChildren });
    }

    /**
     * 根据主键获取详细信息。
     * @param id 主键
     * @return 详情
     * HTTP GET /ocrChar/getInfo/{id}
     * Java method: cn.dbw.luckwheel.controller.OcrCharController.getInfo
     */
    getInfo$GET$ocrChar_getInfo_id(id: number): RestResponse<OcrChar> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`ocrChar/getInfo/${id}` });
    }

    /**
     * 查询所有。
     * @return 所有数据
     * HTTP GET /ocrChar/list
     * Java method: cn.dbw.luckwheel.controller.OcrCharController.list
     */
    list$GET$ocrChar_list(): RestResponse<OcrChar[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`ocrChar/list` });
    }

    /**
     * 分页查询。
     * @param page 分页对象
     * @return 分页对象
     * HTTP GET /ocrChar/page
     * Java method: cn.dbw.luckwheel.controller.OcrCharController.page
     */
    page$GET$ocrChar_page(): RestResponse<Page<OcrChar>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`ocrChar/page` });
    }

    /**
     * 根据主键删除。
     * @param id 主键
     * @return {@code true} 删除成功，{@code false} 删除失败
     * HTTP DELETE /ocrChar/remove/{id}
     * Java method: cn.dbw.luckwheel.controller.OcrCharController.remove
     */
    remove$DELETE$ocrChar_remove_id(id: number): RestResponse<boolean> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`ocrChar/remove/${id}` });
    }

    /**
     * 添加。
     * @param ocrChar
     * @return {@code true} 添加成功，{@code false} 添加失败
     * HTTP POST /ocrChar/save
     * Java method: cn.dbw.luckwheel.controller.OcrCharController.save
     */
    save$POST$ocrChar_save(ocrChar: OcrChar): RestResponse<boolean> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`ocrChar/save`, data: ocrChar });
    }

    /**
     * 根据主键更新。
     * @param ocrChar
     * @return {@code true} 更新成功，{@code false} 更新失败
     * HTTP PUT /ocrChar/update
     * Java method: cn.dbw.luckwheel.controller.OcrCharController.update
     */
    update$PUT$ocrChar_update(ocrChar: OcrChar): RestResponse<boolean> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`ocrChar/update`, data: ocrChar });
    }

    /**
     * HTTP GET /product/add
     * Java method: cn.dbw.luckwheel.controller.LuckProductController.add
     */
    add(map: LuckProduct): RestResponse<R<boolean>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`product/add`, data: map });
    }

    /**
     * HTTP GET /product/lists
     * Java method: cn.dbw.luckwheel.controller.LuckProductController.getLuckProductList
     */
    getLuckProductList(): RestResponse<R<LuckProduct[]>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`product/lists` });
    }

    /**
     * 获取奖品列表
     * @return R<List<LuckProduct>> 奖品列表
     * HTTP GET /product/showlists
     * Java method: cn.dbw.luckwheel.controller.LuckProductController.getLuckProductByShowList
     */
    getLuckProductByShowList(): RestResponse<R<string[]>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`product/showlists` });
    }

    /**
     * HTTP GET /product/update
     * Java method: cn.dbw.luckwheel.controller.LuckProductController.update
     */
    update$GET$product_update(map: LuckProduct): RestResponse<R<boolean>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`product/update`, data: map });
    }

    /**
     * HTTP GET /quiz/getMoreQuestions/{listId}
     * Java method: cn.dbw.luckwheel.controller.QuizController.getMoreQuestions
     */
    getMoreQuestions(listId: string): RestResponse<Question[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`quiz/getMoreQuestions/${listId}` });
    }

    /**
     * HTTP GET /quiz/startQuiz/{listId}
     * Java method: cn.dbw.luckwheel.controller.QuizController.startQuiz
     */
    startQuiz(listId: string): RestResponse<QuizResponse> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`quiz/startQuiz/${listId}` });
    }

    /**
     * HTTP PUT /user
     * Java method: cn.dbw.luckwheel.controller.LuckUserController.updateUser
     */
    updateUser(user: LuckUser): RestResponse<R<any>> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`user`, data: user });
    }

    /**
     * 添加用户
     * @param user
     * @RequestBody LuckUser user
     * @return
     * HTTP POST /user/add
     * Java method: cn.dbw.luckwheel.controller.LuckUserController.addUser
     */
    addUser(user: LuckUser): RestResponse<R<any>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`user/add`, data: user });
    }

    /**
     * 获取用户列表
     * @param page
     * @param size
     * HTTP GET /user/list
     * Java method: cn.dbw.luckwheel.controller.LuckUserController.getUserList
     */
    getUserList(queryParams?: { page?: number; size?: number; }): RestResponse<R<LuckUser[]>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`user/list`, queryParams: queryParams });
    }

    /**
     * HTTP POST /user/register
     * Java method: cn.dbw.luckwheel.controller.LuckUserController.register
     */
    register(user: LuckUser): RestResponse<R<any>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`user/register`, data: user });
    }

    /**
     * HTTP DELETE /user/{id}
     * Java method: cn.dbw.luckwheel.controller.LuckUserController.deleteUser
     */
    deleteUser(id: number): RestResponse<R<any>> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`user/${id}` });
    }

    /**
     * HTTP GET /user/{id}
     * Java method: cn.dbw.luckwheel.controller.LuckUserController.getUserInfo
     */
    getUserInfo(id: number): RestResponse<R<any>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`user/${id}` });
    }

    /**
     * 根据主键获取详细信息。
     * @param id 主键
     * @return 详情
     * HTTP GET /userAction/getInfo/{id}
     * Java method: cn.dbw.luckwheel.controller.UserActionController.getInfo
     */
    getInfo$GET$userAction_getInfo_id(id: string): RestResponse<UserAction> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`userAction/getInfo/${id}` });
    }

    /**
     * 查询所有。
     * @return 所有数据
     * HTTP GET /userAction/list
     * Java method: cn.dbw.luckwheel.controller.UserActionController.list
     */
    list$GET$userAction_list(): RestResponse<UserAction[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`userAction/list` });
    }

    /**
     * 分页查询。
     * @param page 分页对象
     * @return 分页对象
     * HTTP GET /userAction/page
     * Java method: cn.dbw.luckwheel.controller.UserActionController.page
     */
    page$GET$userAction_page(): RestResponse<Page<UserAction>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`userAction/page` });
    }

    /**
     * 根据主键删除。
     * @param id 主键
     * @return {@code true} 删除成功，{@code false} 删除失败
     * HTTP DELETE /userAction/remove/{id}
     * Java method: cn.dbw.luckwheel.controller.UserActionController.remove
     */
    remove$DELETE$userAction_remove_id(id: string): RestResponse<boolean> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`userAction/remove/${id}` });
    }

    /**
     * 添加。
     * @param userAction
     * @return {@code true} 添加成功，{@code false} 添加失败
     * HTTP POST /userAction/save
     * Java method: cn.dbw.luckwheel.controller.UserActionController.save
     */
    save$POST$userAction_save(userAction: UserAction): RestResponse<boolean> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`userAction/save`, data: userAction });
    }

    /**
     * 根据主键更新。
     * @param userAction
     * @return {@code true} 更新成功，{@code false} 更新失败
     * HTTP PUT /userAction/update
     * Java method: cn.dbw.luckwheel.controller.UserActionController.update
     */
    update$PUT$userAction_update(userAction: UserAction): RestResponse<boolean> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`userAction/update`, data: userAction });
    }

    /**
     * HTTP GET /v1/document
     * Java method: cn.dbw.luckwheel.controller.DocumentController.getDocumentList
     */
    getDocumentList(queryParams: { modifyTime: number; page: number; }): RestResponse<Document[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/document`, queryParams: queryParams });
    }

    /**
     * HTTP GET /wheel/lottery
     * Java method: cn.dbw.luckwheel.controller.LuckWheelController.wheelLottery
     */
    wheelLottery(): RestResponse<R<string>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`wheel/lottery` });
    }

    /**
     * HTTP GET /wheel/test
     * Java method: cn.dbw.luckwheel.controller.LuckWheelController.canDog
     */
    canDog(): RestResponse<boolean> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`wheel/test` });
    }

    /**
     * 获取单词列表。
     * @param listId 列表ID
     * @return 单词列表
     * HTTP GET /word/list/{listId}
     * Java method: cn.dbw.luckwheel.controller.WordController.getWords
     */
    getWords(listId: number): RestResponse<Word[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`word/list/${listId}` });
    }

    /**
     * 删除单词。
     * @param listId 列表ID
     * @param wordId 单词ID
     * @return 删除结果
     * HTTP DELETE /word/remove/{listId}/{wordId}
     * Java method: cn.dbw.luckwheel.controller.WordController.deleteWord
     */
    deleteWord(listId: number, wordId: number): RestResponse<void> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`word/remove/${listId}/${wordId}` });
    }

    /**
     * 添加单词。
     * @param wordRequest 单词对象
     * @return 添加的单词对象
     * HTTP POST /word/save
     * Java method: cn.dbw.luckwheel.controller.WordController.addWord
     */
    addWord(wordRequest: WordRequest): RestResponse<Word> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`word/save`, data: wordRequest });
    }

    /**
     * HTTP POST /wordList
     * Java method: cn.dbw.luckwheel.controller.WordListController.createList
     */
    createList(request: CreateListRequest): RestResponse<WordList> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`wordList`, data: request });
    }

    /**
     * HTTP GET /wordList
     * Java method: cn.dbw.luckwheel.controller.WordListController.getLists
     */
    getLists(): RestResponse<WordList[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`wordList` });
    }

    /**
     * HTTP DELETE /wordList/{id}
     * Java method: cn.dbw.luckwheel.controller.WordListController.deleteList
     */
    deleteList(id: string): RestResponse<void> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`wordList/${id}` });
    }

    /**
     * HTTP GET /wordList/{id}
     * Java method: cn.dbw.luckwheel.controller.WordListController.getList
     */
    getList(id: string): RestResponse<WordList> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`wordList/${id}` });
    }

    /**
     * HTTP PUT /wordList/{id}
     * Java method: cn.dbw.luckwheel.controller.WordListController.updateList
     */
    updateList(id: string, request: CreateListRequest): RestResponse<WordList> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`wordList/${id}`, data: request });
    }
}

export type RestResponse<R> = Promise<R>;

function uriEncoding(template: TemplateStringsArray, ...substitutions: any[]): string {
    let result = "";
    for (let i = 0; i < substitutions.length; i++) {
        result += template[i];
        result += encodeURIComponent(substitutions[i]);
    }
    result += template[template.length - 1];
    return result;
}
